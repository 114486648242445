import http from "../configuration/axios";
import { apiRoute } from "../utils/apiroutes";

export class EnquireService {
    static async getEnquiryList(pageNo?: any, pageSize?:any) {
        try {
            return (await http.get(`${apiRoute.enquiry.getEnquiryList}?pageSize=${pageSize}&pageNo=${pageNo}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async getEnquiryDetails(id: any) {
        try {
            return (await http.get(`${apiRoute.enquiry.getEnquiryDetails}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }


    static async updateEnquiryDetails(data: any) {
        try {
            return (await http.put(`${apiRoute.enquiry.updateEnquiryDetails}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }
}