import React, { useEffect, useState } from 'react';
import DeleteConfirmationModal from '../ui/DeleteConfirmationModal';
import { messages } from '../../utils/messages';
import { toast } from 'react-toastify';
import Loader from '../ui/Loader';
import Pagination from '../ui/Pagination';
import { PaymentService } from '../../services/PaymentService';
import moment from 'moment';
import PaymentDetails from './PaymentDetails';

const PaymentList = () => {
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [openPaymentDetailsModal, setOpenPaymentDetailsModal] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [paymentList, setPaymentList] = useState<any[]>([]);
  const [selectedPayment, setSelectedPayment] = useState<any>(null);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [pageNo, setPageNo] = useState<any>(1);
  const [paymentMethodList, setPaymentMethodList] = useState<any>([]);
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [searchText, setSearchText] = useState('');
  
  const handleDeleteConfirmationModal = (flag: boolean, payment?: any) => {
    setOpenDeleteConfirmationModal(flag);
    setSelectedPayment(payment);
  };

  const handlePaymentDetailsModal = (flag: boolean, payment?: any) => {
    setOpenPaymentDetailsModal(flag);
    setSelectedPayment(payment);
  };

  useEffect(()=>{
    getPaymentList();
  },[pageNo])

  const getPaymentList = async () => {
    try {
      setLoader(true);
      const response: any = await PaymentService.getPaymentList(pageNo, pageSize, searchText, paymentMethodId);
      if (response?.status === 200) {
        setPaymentList(response?.data?.data?.content || []);
        setTotalCount(response?.data?.data?.totalCount);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const getPaymentMethods = async () => {
    try {
      setLoader(true);
      const response: any = await PaymentService.getPaymentMethod();
      if (response?.status === 200) {
        setPaymentMethodList(response?.data?.data || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  const deletePayment = async (id: any) => {
    try {
      setLoader(true);
      const response = await PaymentService.deletePayment(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.payment?.deletedSuccess);
        setOpenDeleteConfirmationModal(false);
        setSelectedPayment(null);
        getPaymentList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handlePageChange = (data: any) => {
    setPageNo(data);
  }

  
  const handleApplyFilters = () => {
    setPageNo(1);
    getPaymentList();
  }

  const handleResetFilter = async () => {
    setPageNo(1);
    setPaymentMethodId((prevValue: any) => '');
    setSearchText((prevValue: any) => ''); 
    setLoader(true);
    const response: any = await PaymentService.getPaymentList(1, pageSize);
    if (response?.status === 200) {
      setPaymentList(response?.data?.data?.content || []);
      setTotalCount(response?.data?.data?.totalCount);
      setLoader(false);
    } else {
      handleRequestError();
    }
  };

  return (
    <section>
      <div className='flex flex-row justify-between'>
        <div>
          <h2 className='font-semibold'>Payments</h2>
          <div className='text-gray-400 text-sm'>Payments {'>'} Payments list</div>
        </div>
      </div>      
      <div className='bg-white rounded-lg p-4 mt-4'>
      <div className='w-full mb-5 flex justify-between'>
          <div className='flex'>
            <div className='w-[350px]'>
              <div className="relative w-full">
                <i className="absolute top-[15px] left-[10px] lni lni-search-alt text-gray-500"></i>
                <input className="w-full my-[3px] py-[8px] ps-[35px] pe-[16px] border border-[#dbdfe9] rounded-[8px]"
                  type="text" placeholder="Search by user name..." value={searchText} onChange={ (event) => { setSearchText(event.target.value) } }
                />
              </div>
            </div>
            <div className="form-control w-[300px] ms-4">
              <select name='categoryId' value={paymentMethodId} onChange={(event) => { setPaymentMethodId(event.target.value) }}>
                <option value="" hidden>Select payment method</option>
                { paymentMethodList?.map((method: any) => (
                  <option key={method?.id} value={method?.id}>{method?.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div>
              <button onClick={ handleApplyFilters } disabled={!searchText && !paymentMethodId} className='px-[25px] py-[10px] bg-secondary text-white text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300 disabled:text-white'>Apply</button>
              <button onClick={ handleResetFilter } disabled={!searchText && !paymentMethodId} className='px-[25px] py-[10px] bg-slate-200 text-black text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300 disabled:text-white'>Reset</button>
          </div>
        </div>
        <div className="relative overflow-x-auto">
            <table className="w-full">
                <thead className="text-sm text-slate-400 uppercase border-b-2 border-dashed border-slate-200 text-left">
                    <tr>
                        <th scope="col" className="px-4 py-3 w-[40%] font-semibold">
                          User Name
                        </th>
                        <th scope="col" className="px-4 py-3 w-[15%] font-semibold">
                          Amount (₹)
                        </th>
                        <th scope="col" className="px-4 py-3 w-[15%] font-semibold">
                          Date
                        </th>
                        <th scope="col" className="px-4 py-3 w-[15%] font-semibold">
                          Method
                        </th>
                        <th scope="col" className="px-4 py-3 w-[10%] font-semibold">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { (paymentList?.length > 0) ? (paymentList?.map((payment: any) => (
                      <tr key={payment?.id} className="bg-white text-md border-b border-slate-200 text-slate-900">
                          <td className="px-4 py-3 whitespace-nowrap">
                              {payment?.user_name}
                          </td>
                          <td className="px-4 py-3">
                              {payment?.amount}
                          </td>
                          <td className="px-4 py-3">
                              {moment(payment?.date).format('DD MMM YYYY')}
                          </td>
                          <td className="px-4 py-3">
                              {payment?.method_name}
                          </td>
                          <td className="px-4 py-3">
                            <div className='flex'>
                              <button onClick={ () => handlePaymentDetailsModal(true, payment) } className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-primary hover:ring-2 hover:ring-blue-200 me-3'><i className="lni lni-eye"></i></button>
                              <button onClick={ () => handleDeleteConfirmationModal(true, payment) } className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-red-600 hover:ring-2 hover:ring-red-200'><i className="lni lni-trash-can"></i></button>
                            </div>
                          </td>
                      </tr>
                    ))) : (
                      <tr><td colSpan={8} className='py-8 text-gray-500 text-center'>Payment data not found.</td></tr>
                    )}
                    {/* { (paymentList?.length === 0) && (<tr> <td className='p-3'>Payment details not found.</td> </tr>) } */}
                </tbody>
            </table>
            <Pagination totalCount={totalCount} pageSize={pageSize} onPageChange={ (data) => { handlePageChange(data) } }/>
        </div>
      </div>
      { loader && <Loader/> }
      { openDeleteConfirmationModal && <DeleteConfirmationModal hide={ () => { handleDeleteConfirmationModal(false) }}  deleteConfirmation={(id: any) => deletePayment(id)} id={selectedPayment?.id}/> }
      { openPaymentDetailsModal && <PaymentDetails onClose={ () => { handlePaymentDetailsModal(false) }}  paymentId={selectedPayment?.id}/> }
    </section>
  );
};

export default PaymentList;
