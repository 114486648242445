import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import Loader from "../ui/Loader";
import { toast } from "react-toastify";
import { messages } from "../../utils/messages";
import { ModuleService } from "../../services/ModuleService";
import { PaymentService } from "../../services/PaymentService";
import moment from "moment";
import { ProductService } from "../../services/ProductService";
import MultiSelectDropdown from "../ui/MultiSelectDropdown";
import { UserService } from "../../services/UserService";
import { SubCategoryService } from "../../services/SubCategoryService";

const AddPayment = ({ onClose, onSubmit, userData, productData, requestId }: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [userName, setUserName] = useState(userData?.name || '');
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<any>(productData?.id? [productData?.id] : []);
  const [paymentMethodList, setPaymentMethodList] = useState<any>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const [allProductList, setAllProductList] = useState<any[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<any[]>([]);
  const [subCategoryId, setSubCategoryId] = useState('');
  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    let numberregx = /^\d+$/;
    let errors: any = {};
    let isValid = true;

    if (!amount) {
      errors.amount = 'Amount is required.';
      isValid = false;
    }else if(!numberregx.test(amount)){
      errors.amount = 'Please enter a valid amount number.';
      isValid = false;
    }

    if (selectedProduct?.length == 0) {
      errors.selectedProduct = 'Please select product.';
      isValid = false;
    }

    if (!paymentMethod) {
      errors.paymentMethod = 'Payment method is required.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    getPaymentMethods();
    getProductList();
    getSubCategoryList();
  }, [])

  const getPaymentMethods = async () => {
    try {
      setLoader(true);
      const response: any = await PaymentService.getPaymentMethod();
      if (response?.status === 200) {
        setPaymentMethodList(response?.data?.data || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  useEffect(() => {
    if (subCategoryId) {
      const filteredData = allProductList.filter(el => el?.sub_category_id == subCategoryId).sort((a, b) => a.name.localeCompare(b.name));
      setProductList(filteredData);
    }
  }, [subCategoryId, allProductList]);  

  const getSubCategoryList = async () => {
    try {
      setLoader(true);
      const response: any = await SubCategoryService.getSubCategoryList();
      if (response?.status === 200) {
        let sortedData = response?.data?.data?.content.sort((a: any, b:any) => a.name.localeCompare(b.name))
        setSubCategoryList(sortedData || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const getProductList = async () => {
    try {
      setLoader(true);
      const userresponse: any = await UserService.getUserProductList(userData?.id);
      const response: any = await ProductService.getProductList();
      if (response?.status === 200) {
        let userProductIds = userresponse?.data?.data?.map((el: any) => el.id);
        let paidProducts = response?.data?.data?.content?.filter((el: any) => ((Number(el.pay_status) === 1) && (el.status === 1)) && !userProductIds.includes(el.id));
        setAllProductList(paidProducts || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };
  
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { 
          user_id: userData?.id, 
          amount: amount, 
          payment_mode: paymentMethod, 
          selectedProduct : selectedProduct,
          date: moment().format('YYYY-MM-DD'),  
          requestId: requestId? requestId: null,
          status: 1 
        };
        const response: any = await PaymentService.savePayment(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(requestId? messages.product?.requestAccepted: messages.payment?.createdSuccess);
          onSubmit();
        } else {
          handleRequestError();
        }
      } catch (error) {
        handleRequestError();
      }
    }
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  const handelSelectionChange = (data: any) => {
    setSelectedProduct(data);
  }

  return (
   <>
    <Modal>
      <div className="w-[40rem]">
        <div className="bg-white">
          <div className="px-4 py-4 flex flex-row justify-between">
            <h2 className="font-[600] text-[18px]">Add Payment</h2>
            <div>
              <i onClick={ onClose } className="lni lni-close cursor-pointer font-[600] hover:text-red-600"></i>
            </div>
          </div>
          <div className="px-5 py-3">
            <div className="form-control">
              <label htmlFor="user">User Name</label>
              <input type="text" placeholder="Enter user name" id="moduleName" name="moduleName" value={userName} onChange={(event) => { setUserName(event.target.value);}} disabled />
            </div>
            <div className="form-control">
              <label htmlFor="user">Sub Category</label>
              <select name='subCategoryId' value={subCategoryId} onChange={(event) => { setSubCategoryId(event.target.value);}} >
                <option value="" hidden>Select sub category </option> 
                { subCategoryList?.map((subcat: any) => (
                  <option key={subcat?.id} value={subcat?.id}>{subcat?.name}</option>
                ))}
              </select>
              {errors?.paymentMethod && <span className="text-red-600 text-sm ms-2 font-medium">{errors.paymentMethod}</span>}
            </div>
            <div className="form-control">
              <label htmlFor="user">Product</label>
              <MultiSelectDropdown disabled={requestId? true:false} options={productList} selectedValue={selectedProduct} placeHolder={"Select product"} selectionChange={handelSelectionChange}/>
              {errors?.selectedProduct && <span className="text-red-600 text-sm ms-2 font-medium">{errors.selectedProduct}</span>}
            </div>
            <div className="form-control">
              <label htmlFor="user">Amount</label>
              <input type="number" placeholder="Enter amount" id="moduleName" name="moduleName" value={amount} onChange={(event) => { setAmount(event.target.value);}} />
              {errors?.amount && <span className="text-red-600 text-sm ms-2 font-medium">{errors.amount}</span>}
            </div>
            <div className="form-control">
              <label htmlFor="user">Payment Method</label>
              <select name="paymentMethod" value={paymentMethod} onChange={(event) => { setPaymentMethod(event.target.value);}} >
                <option value="" hidden>Select payment method</option> 
                { paymentMethodList?.map((method: any) => (
                  <option key={method?.id} value={method?.id}>{method?.name}</option>
                ))}
              </select>
              {errors?.paymentMethod && <span className="text-red-600 text-sm ms-2 font-medium">{errors.paymentMethod}</span>}
            </div>
          </div>
        </div>
        <div className="border-t-2 border-slate-100 px-4 mt-10 mb-10 py-3 flex justify-end">
          <button type="button" onClick={ onClose } className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"> Cancel </button>
          <button type="button" onClick={ handleSubmit } className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"> Submit </button>
        </div>
      </div>
    </Modal>
    { loader && <Loader/> }
   </>
  );
};

export default AddPayment;
