import http from "../configuration/axios";
import { apiRoute } from "../utils/apiroutes";

export class AuthService {
    static async login(data: any) {
        try {
            return (await http.post(`${apiRoute.auth.login}`, data));
        } catch (error) {
            throw error;
        }
    }

    static async getUserDetails() {
        try {
            return (await http.get(`${apiRoute.auth.userdetails}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }
}