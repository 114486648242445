import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import Loader from "../ui/Loader";
import { PaymentService } from "../../services/PaymentService";
import { messages } from "../../utils/messages";
import { toast } from "react-toastify";
import moment from "moment";

const PaymentDetails = ({ onClose, paymentId }: any) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [paymentDetails, setPaymentDetails] = useState<any>(null);

    useEffect(()=>{
        getPaymentDetails();
      },[paymentId])
    
      const getPaymentDetails = async () => {
        try {
          setLoader(true);
          const response: any = await PaymentService.getPaymentDetails(paymentId);
          if (response?.status === 200) {
            setPaymentDetails(response?.data?.data || null);
            setLoader(false);
          } else {
            handleRequestError();
          }
        } catch (error) {
          handleRequestError();
        }
      };

      const handleRequestError = () => {
        setLoader(false);
        toast.error(messages.error);
      };

    return (
        <>
            <Modal>
                <div className="w-[35rem]">
                    <div className="bg-white">
                        <div className="px-4 py-4 flex flex-row justify-between">
                        <h2 className="font-[600] text-[18px]">Payment Details</h2>
                        <div>
                            <i onClick={onClose} className="lni lni-close cursor-pointer font-[600] hover:text-red-600"></i>
                        </div>
                        </div>
                        <div className="px-5 py-3 pt-0">
                            <div className="px-4 py-4 bg-slate-50 rounded-lg text-[15px] mb-4">
                                <div className="mb-2 flex flex-row justify-between">
                                <div>
                                        <span className="text-gray-600 me-3"> User Name: </span>
                                        <span className="font-semibold text-black"> {paymentDetails?.user_name} </span>
                                </div>
                                <div>
                                        <span className="text-gray-600 me-3"> Date: </span>
                                        <span className="font-semibold text-black"> {moment(paymentDetails?.date).format('DD MMM YYYY')} </span>
                                    </div>
                                </div>
                                <div className="mb-2">
                                <span className="text-gray-600 me-3"> Amount: </span>
                                <span className="font-semibold text-black"> {paymentDetails?.amount} ({paymentDetails?.method_name})</span>
                                </div>
                            </div>
                            <div>
                                {(paymentDetails?.productList?.length > 0) && (<h2 className="font-semibold">Product List: </h2>)}
                                <div>
                                    {paymentDetails?.productList?.map((product: any) => (
                                        <div key={product?.id} className="px-3 py-2 bg-slate-50 font-medium rounded-md ring-2 ring-slate-100 my-3 text-sm">{product?.name}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            { loader && <Loader/> }
        </>
    );
}

export default PaymentDetails;