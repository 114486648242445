import React, { useEffect, useState } from "react";
import Modal from "../../ui/Modal";
import Loader from "../../ui/Loader";
import { toast } from "react-toastify";
import { messages } from "../../../utils/messages";
import { ProductService } from "../../../services/ProductService";
import FileDragAndDrop from "../../ui/FileDragAndDrop";

const AddProductImage = ({ onClose, onSubmit, editMode, moduleData }: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [imageLabel, setImageLabel] = useState('');
  const [imageData, setImageData] = useState<any>('');
  const [errors, setErrors] = useState<any>({});
  

  const validateForm = () => {
    let errors: any = {};
    let isValid = true;

    if (!imageLabel.trim()) {
      errors.imageLabel = 'Label name is required.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleDrop = (fileData: any) => {
    setImageData(fileData);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        const formData = new FormData();
        formData.append('image', imageData);
        formData.append('labelname', imageLabel);

        const response: any = await ProductService.uploadProductImages(formData);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.productImage?.createdSuccess);
          onSubmit();
        } else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.productImage?.alreadyExist);
        } else {
          setLoader(false);
          toast.error(messages.error);
        }
      } catch (error) {
        console.error("Error occurred", error);
      }
    }
  };


  return (
   <>
    <Modal>
      <div className="w-[30rem]">
        <div className="bg-white">
          <div className="px-4 py-4 flex flex-row justify-between">
            <h2 className="font-[600] text-[18px]">Upload Product Image</h2>
            <div>
              <i onClick={ onClose } className="lni lni-close cursor-pointer font-[600] hover:text-red-600"></i>{" "}
            </div>
          </div>
          <div className="px-5 py-3">
            <div className="form-control">
              <label htmlFor="user">Image Label</label>
              <input type="text" placeholder="Enter image Label" id="imageLabel" name="imageLabel" value={imageLabel} onChange={(event) => { setImageLabel(event.target.value);}} />
              {errors?.imageLabel && <span className="text-red-600 text-sm ms-2 font-medium">{errors.imageLabel}</span>}
            </div>
            <div className="form-control">
              <label htmlFor="name">Upload File</label>
              <FileDragAndDrop onDrop={handleDrop} fileName={''}/>
            </div>
          </div>
        </div>
        <div className="border-t-2 border-slate-100 px-4 py-3 flex justify-end">
          <button type="button" onClick={ onClose } className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"> Cancel </button>
          <button type="button" onClick={ handleSubmit } className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"> Submit </button>
        </div>
      </div>
    </Modal>
    { loader && <Loader/> }
   </>
  );
};

export default AddProductImage;
