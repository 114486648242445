import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import Loader from "../ui/Loader";
import { PaymentService } from "../../services/PaymentService";
import { messages } from "../../utils/messages";
import { toast } from "react-toastify";
import moment from "moment";
import { EnquireService } from "../../services/EnquireService";
import StatusBadge from "../ui/StatusBadge";

const EnquiryDetails = ({ onClose, enquireId, onUpdate }: any) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [enquiryDetails, setEnquiryDetails] = useState<any>(null);
    const [status, setStatus] = useState<any>('');

    useEffect(()=>{
        getEnquiryDetails();
      },[enquireId])
    
      const getEnquiryDetails = async () => {
        try {
          setLoader(true);
          const response: any = await EnquireService.getEnquiryDetails(enquireId);
          if (response?.status === 200) {
            setEnquiryDetails(response?.data?.data || null);
            setStatus(response?.data?.data?.status)
            setLoader(false);
          } else {
            handleRequestError();
          }
        } catch (error) {
          handleRequestError();
        }
      };

      const handleRequestError = () => {
        setLoader(false);
        toast.error(messages.error);
      };

      const handleUpdateStatus = async () => {
        try {
          setLoader(true);
          let data = {
            id: enquireId,
            status: status
          }
          const response: any = await EnquireService.updateEnquiryDetails(data);
          if (response?.status === 200) {
            setLoader(false);
            onUpdate();
          } else {
            handleRequestError();
          }
        } catch (error) {
          handleRequestError();
        }
      }

    return (
        <>
            <Modal>
                <div className="w-[35rem]">
                    <div className="bg-white">
                        <div className="px-4 py-4 flex flex-row justify-between">
                        <h2 className="font-[600] text-[18px]">Enquiry Details</h2>
                        <div>
                            <i onClick={onClose} className="lni lni-close cursor-pointer font-[600] hover:text-red-600"></i>
                        </div>
                        </div>
                        <div className="px-5 py-3 pt-0">
                            <div className="px-4 py-4 bg-slate-50 rounded-lg text-[15px] mb-4">
                                <div className="mb-3 flex flex-row justify-between">
                                    <div>
                                          <span className="text-gray-600 me-3"> Enquirer Name: </span>
                                          <span className="font-medium text-black"> {enquiryDetails?.name} </span>
                                    </div>
                                    <div>
                                          <span className="text-gray-600 me-3"> Date: </span>
                                          <span className="font-medium text-black"> {moment(enquiryDetails?.created_at).format('DD MMM YYYY')} </span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                      <span className="text-gray-600 me-3"> Email: </span>
                                      <span className="font-medium text-black"> {enquiryDetails?.email} </span>
                                </div>
                                <div className="mb-3">
                                      <span className="text-gray-600 me-3"> Phone No: </span>
                                      <span className="font-medium text-black"> {enquiryDetails?.phone_no} </span>
                                </div>
                                <div className="mb-3 flex">
                                      <span className="text-gray-600 me-3"> Status: </span>
                                      <StatusBadge status={enquiryDetails?.status}/>
                                </div>
                                <div>
                                      <span className="text-gray-600 me-3"> Message: </span>
                                      <div className="font-medium text-black"> {enquiryDetails?.message} </div>
                                </div>
                            </div>
                            <div className="px-4 py-4 mb-4">
                              <div className="form-control">
                                <label htmlFor="user">Status</label>
                                <div className="flex flex-row items-center">
                                  <select className="w-[300px]" name="moduelId" value={status} onChange={(event) => { setStatus(event.target.value);}}>
                                    <option value="" selected hidden>Select Module</option>
                                    <option value="3">In Progress</option>
                                    <option value="4">Rejecte</option>
                                    <option value="5">Close</option>
                                  </select>
                                  <button type="button" onClick={handleUpdateStatus} disabled={!status} className="inline-flex w-full justify-center rounded-md bg-primary h-[40px] px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 ml-3 sm:w-auto disabled:bg-gray-300"> Update Status </button>
                                </div>
                                {/* {errors?.moduelId && <span className="text-red-600 text-sm ms-2 font-medium">{errors.moduelId}</span>} */}
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            { loader && <Loader/> }
        </>
    );
}

export default EnquiryDetails;