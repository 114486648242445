import React, { useState } from "react";
import Modal from "../ui/Modal";
import { UserService } from "../../services/UserService";
import { toast } from "react-toastify";
import { messages } from "../../utils/messages";
import PasswordVisibility from "../ui/PasswordVisibility";
import Loader from "../ui/Loader";

const AddUser = ({ onClose, onSubmit, editMode, userData  }: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [name, setName] = useState(editMode? userData?.name : '');
  const [email, setEmail] = useState(editMode? userData?.email : '');
  const [phoneNo, setPhoneNo] = useState(editMode? userData?.phone_no : '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    let errors: any = {};
    let isValid = true;

    if (!name.trim()) {
      errors.name = 'Name is required.';
      isValid = false;
    }

    if (!email.trim()) {
      errors.email = 'Email is required.';
      isValid = false;
    }else if (!emailRegex.test(email)) {
      errors.email = 'Invalid email format.';
      isValid = false;
    } 

    if (!phoneNo) {
      errors.phoneNo = 'Phone no is required';
      isValid = false;
    }else if (!phoneRegex.test(phoneNo)) {
      errors.phoneNo = 'Invalid phone no.';
      isValid = false;
    } 

    if ((!editMode) || (editMode && isChangePassword)) {
      if ( !password && !password.trim()) {
          errors.password = 'Password is required';
          isValid = false;
      } else if (!passwordRegex.test(password)) {
          errors.password = 'The password should be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number';
          isValid = false;
      }
  
      if (!confirmPassword.trim()) {
          errors.confirmPassword = 'Confirm password is required';
          isValid = false;
      } else if (confirmPassword.trim() !== password.trim()) {
          errors.confirmPassword = 'Passwords do not match';
          isValid = false;
      }
    }
  


    setErrors(errors);
    return isValid;
  };


  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { name: name, phone_no: phoneNo, email: email, password: password, status: 1, role: 3 };
        const response: any = await UserService.saveUser(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.user?.createdSuccess);
          onSubmit();
        } else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.user?.alreadyExist);
        } else {
          handleRequestError();
        }
      } catch (error) {
        handleRequestError();
      }
    }
  };

  const handleUpdate = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data: any = {id: userData?.id, name: name, phone_no: phoneNo, email: email};
        if(isChangePassword && password){
          data.password = password;
        }
        const response: any = await UserService.updateUser(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.user?.updatedSuccess);
          onSubmit();
        }else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.user?.alreadyExist);
        } else {
          handleRequestError();
        }
      } catch (error) {
        handleRequestError();
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  return (
    <>
      <Modal>
        <form autoComplete="off">
          <div className="w-[30rem]">
            <div className="bg-white">
              <div className="px-4 py-4 flex flex-row justify-between">
                <h2 className="font-[600] text-[18px]">{ !editMode? 'Add':'Edit' } User</h2>
                <div>
                  <i onClick={onClose} className="lni lni-close cursor-pointer font-[600] hover:text-red-600"></i>{" "}
                </div>
              </div>
              <div className="px-5 py-3">
                <div className="form-control">
                  <label htmlFor="user">Name</label>
                  <input type="text" placeholder="Enter name" name="name" value={name} onChange={(event) => { setName(event.target.value);}}/>
                  {errors?.name && <span className="text-red-600 text-sm ms-2 font-medium">{errors.name}</span>}
                </div>
                <div className="form-control">
                  <label htmlFor="user">Email</label>
                  <input type="email" placeholder="Enter email" name="email" value={email} onChange={(event) => { setEmail(event.target.value);}}/>
                  {errors?.email && <span className="text-red-600 text-sm ms-2 font-medium">{errors.email}</span>}
                </div>
                <div className="form-control">
                  <label htmlFor="user">Phone No</label>
                  <input type="text" placeholder="Enter phone no" name="phoneNo" value={phoneNo} onChange={(event) => { setPhoneNo(event.target.value);}}/>
                  {errors?.phoneNo && <span className="text-red-600 text-sm ms-2 font-medium">{errors.phoneNo}</span>}
                </div>
                { (editMode && !isChangePassword) && (<div onClick={() => setIsChangePassword(true)} className="ms-2 font-semibold text-primary cursor-pointer text-sm hover:text-blue-500 hover:underline underline-offset-1 w-fit">Change Password</div>)}
                { (!editMode || isChangePassword)  && 
                  (
                    <>
                    <div className="form-control">
                      <label htmlFor="user">Password</label>
                      <div className="input-group">
                        <input type={showPassword? 'text':'password'} placeholder="Enter password" name="password" value={password} onChange={(event) => { setPassword(event.target.value);}}/>
                        <PasswordVisibility visibility={showPassword} onClick={togglePasswordVisibility}/>
                      </div>
                      {errors?.password && <span className="text-red-600 text-sm ms-2 font-medium">{errors.password}</span>}
                    </div>
                    <div className="form-control">
                      <label htmlFor="user">Confirm Password</label>
                      <div className="input-group">
                          <input type={showConfirmPassword? 'text':'password'} placeholder="Enter confirm password" name="confirmPassword" value={confirmPassword} onChange={(event) => { setConfirmPassword(event.target.value);}}/>
                          <PasswordVisibility visibility={showConfirmPassword} onClick={toggleConfirmPasswordVisibility}/>
                      </div>
                      {errors?.confirmPassword && <span className="text-red-600 text-sm ms-2 font-medium">{errors.confirmPassword}</span>}
                    </div>
                    </>
                  )
                }
              </div>
            </div>
            <div className="border-t-2 border-slate-100 px-4 py-3 flex justify-end">
              <button type="button" onClick={onClose} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"> Cancel </button>
              { !editMode ? 
                (<button type="button" onClick={ handleSubmit }  className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"> Submit </button>) :
                (<button type="button" onClick={ handleUpdate }  className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"> Update </button>)
              }
            </div>
          </div>
        </form>
      </Modal>
      { loader && <Loader/> }
    </>
  );
};

export default AddUser;
