import http from "../configuration/axios";
import { apiRoute } from "../utils/apiroutes";

export class PaymentService {
    static async getPaymentList(pageNo?: any, pageSize?:any, search?: any, paymentMethod?: any) {
        try {
            let url = `${apiRoute.payment.getPayment}`;
            if(pageNo && pageSize){
                url = url + `?pageSize=${pageSize}&pageNo=${pageNo}`;
            }

            if(search?.trim()){
                url = url + `&search=${search}`;
            }

            if(paymentMethod){
                url = url + `&paymentMethod=${paymentMethod}`;
            }

            return (await http.get(`${url}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async getPaymentDetails(id: any) {
        try {
            return (await http.get(`${apiRoute.payment.getPaymentById}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async getPaymentMethod() {
        try {
            return (await http.get(`${apiRoute.payment.paymentMethod}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async savePayment(data: any) {
        try {
            return (await http.post(`${apiRoute.payment.createPayment}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async updatePayment(data: any) {
        try {
            return (await http.put(`${apiRoute.payment.updatePayment}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async deletePayment(id: any) {
        try {
            return (await http.delete(`${apiRoute.payment.deletePayment}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async revertPayment(id: any) {
        try {
            return (await http.put(`${apiRoute.payment.revertPayment}${id}`, {}, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }
}