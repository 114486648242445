import React, { useEffect, useState } from 'react';
import DeleteConfirmationModal from '../ui/DeleteConfirmationModal';
import { ProductService } from '../../services/ProductService';
import { messages } from '../../utils/messages';
import { toast } from 'react-toastify';
import StatusBadge from '../ui/StatusBadge';
import Loader from '../ui/Loader';
import Pagination from '../ui/Pagination';
import { useNavigate } from 'react-router-dom';
import PayStatusBadge from '../ui/PayStatusBadge';
import RevertConfirmationModal from '../ui/RevertConfirmationModal';
import { CategoryService } from '../../services/CategoryService';
import Search from '../ui/Search';
import { ModuleService } from '../../services/ModuleService';
import moment from 'moment';
import AddPayment from '../payment/AddPayment';

const RequestedProductList = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [requestList, setRequestList] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<any>(10);
  const [moduleId, setModuleId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState<any>(0);
  const [pageNo, setPageNo] = useState<any>(1);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [selectedRequestId, setSelectedRequestId] = useState<any | null>(null);
  const navigate = useNavigate();
  

  useEffect(()=>{
    getUsersRequestedProductsList();
  }, [pageNo])

  const handlePaymentModal = (flag: boolean, data?:any) => { 
    let userdata = {
      id: data?.user_id,
      name: data?.user_name
    }

    let productdata = {
      id: data?.product_id,
      name: data?.product_name
    }

    setSelectedUser(userdata || null);
    setSelectedRequestId(data?.id || null);
    setSelectedProduct(productdata || null);
    setShowPaymentModal(flag);
  };

  const handlePaymentSubmit = () => { 
    setShowPaymentModal(false);
    setSelectedUser(null);
    setSelectedProduct(null);
    getUsersRequestedProductsList();
  };
  
  const rejectProductRequest = async (id: any) => {
    try {
      setLoader(true);
      const response = await ProductService.rejectProductRequest(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.product?.requestRejected);
        getUsersRequestedProductsList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const getUsersRequestedProductsList = async () => {
    try {
      setLoader(true);
      const response: any = await ProductService.getRequestedProductsList(pageNo, pageSize, searchText);
      if (response?.status === 200) {
        setRequestList(response?.data?.data?.content || []);
        setTotalCount(response?.data?.data?.totalCount);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };


  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  const handlePageChange = (data: any) => {
    setPageNo(data);
  }

  const handleApplyFilters = () => {
    setPageNo(1);
    getUsersRequestedProductsList();
  }

  const handleResetFilter = async () => {
    setPageNo(1);
    setCategoryId('');
    setSearchText(''); 
    setModuleId('')
    setLoader(true);
      const response: any = await ProductService.getRequestedProductsList(1, pageSize);
      if (response?.status === 200) {
        setRequestList(response?.data?.data?.content || []);
        setTotalCount(response?.data?.data?.totalCount);
        setLoader(false);
      } else {
        handleRequestError();
      }
  };
   

  return (
    <section>
      <div className='flex flex-row justify-between'>
        <div>
          <h2 className='font-semibold'>User Request</h2>
          <div className='text-gray-400 text-sm'>User {'>'} User request list</div>
        </div>
      </div>      
      <div className='bg-white rounded-lg p-4 mt-4'>
        <div className='w-full mb-5 flex justify-between'>
          <div className='flex'>
            <div className='w-[400px]'>
              <div className="relative w-full">
                <i className="absolute top-[15px] left-[10px] lni lni-search-alt text-gray-500"></i>
                <input className="w-full my-[3px] py-[8px] ps-[35px] pe-[16px] border border-[#dbdfe9] rounded-[8px]"
                  type="text" placeholder="Search by user and product name..." value={searchText} onChange={ (event) => { setSearchText(event.target.value) } }
                />
              </div>
            </div>
          </div>
          <div>
              <button onClick={ handleApplyFilters } disabled={!searchText && !categoryId && !moduleId} className='px-[25px] py-[10px] bg-secondary text-white text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300'>Apply</button>
              <button onClick={ handleResetFilter } disabled={!searchText && !categoryId && !moduleId} className='px-[25px] py-[10px] bg-slate-200 text-black text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300 disabled:text-white'>Reset</button>
          </div>
        </div>
        <div className="relative overflow-x-auto">
            <table className="w-full">
                <thead className="text-sm text-slate-400 uppercase border-b-2 border-dashed border-slate-200 text-left">
                    <tr>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          User
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          Phone No
                        </th>
                        <th scope="col" className="px-4 py-3 w-[30%] font-semibold">
                          Product Name
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          Date
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          Status
                        </th>
                        <th scope="col" className="px-4 py-3 w-[10%] font-semibold">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { (requestList?.length > 0) ? (requestList?.map((request: any) => (
                      <tr key={request?.id} className="bg-white text-md border-b border-slate-200 text-slate-900">
                          <td className="px-4 py-3 whitespace-nowrap">
                              <div>
                                  <p className='text-medium'>{request?.user_name}</p>
                                  <p className='text-xs text-slate-500'>{request?.email}</p>
                              </div>
                          </td>
                          <td className="px-4 py-3">
                              {request?.phone_no}
                          </td>
                          <td className="px-4 py-3">
                              {request?.product_name}
                          </td>
                          <td className="px-4 py-3">
                              {moment(request?.created_at).format('DD MMM YYYY HH:mm A')}
                          </td>
                          <td className="px-4 py-3">
                              <StatusBadge status={request?.status}/>
                          </td>
                          <td className="px-4 py-3">
                             {request?.status === 1 && 
                              <div className='flex'>
                                <button onClick={() => handlePaymentModal(true, request) } className='text-[18px] flex justify-center items-center me-4 text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-green-600 hover:ring-2 hover:ring-green-200'><i className="lni lni-checkmark text-sm text-bold"></i></button>
                                <button onClick={() => rejectProductRequest(request?.id)} className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-red-600 hover:ring-2 hover:ring-red-200 me-4' title="Rejact"><i className="lni lni-close text-sm text-bold"></i></button>
                              </div>
                             }
                          </td>
                      </tr>
                    ))) : (
                      <tr><td colSpan={8} className='py-8 text-gray-500 text-center'>User request data not found.</td></tr>
                    )}
                </tbody>
            </table>
            <Pagination totalCount={totalCount} pageSize={pageSize} onPageChange={ (data) => { handlePageChange(data) } }/>
        </div>
      </div>
      { loader && <Loader/> }
      { showPaymentModal && <AddPayment userData={selectedUser} productData={selectedProduct} requestId={selectedRequestId} onSubmit={ handlePaymentSubmit } onClose={() => handlePaymentModal(false)}/> }
    </section>
  );
};

export default RequestedProductList;
