import http from "../configuration/axios";
import { apiRoute } from "../utils/apiroutes";

export class ModuleService {
    static async getModuleList(pageNo?: any, pageSize?:any) {
        try {
            let api = `${apiRoute.module.getModule}`;
            if(pageSize && pageNo){
                api = api + `?pageSize=${pageSize}&pageNo=${pageNo}`
            }
            return (await http.get(api, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async saveModule(data: any) {
        try {
            return (await http.post(`${apiRoute.module.createModule}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async updateModule(data: any) {
        try {
            return (await http.put(`${apiRoute.module.updateModule}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async uploadModuleImage(data: any) {
        try {
            console.log(data)
            return (await http.post(`${apiRoute.module.uploadModuleImage}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                  'content-type': 'multipart/form-data'
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async deleteModule(id: any) {
        try {
            return (await http.delete(`${apiRoute.module.deleteModule}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async revertModule(id: any) {
        try {
            return (await http.put(`${apiRoute.module.revertModule}${id}`, {}, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }
}