export const messages: any = {
    error: "Something went wrong. Please try after sometime.",
    login: {
        invalidCreds: "Invalid username and password",
        userNotFound: "User details not found"
    },
    user: {
        createdSuccess: "User created successfully.",
        updatedSuccess: "User updated successfully.",
        deletedSuccess: "User deleted successfully.",
        revertedSuccess: "User reverted successfully.",
        alreadyExist: "Record already exist.",
        recordNotFound: "User not Found.",
        profileUpdateSuccess: "Profile updated successfully.",
        productRemovedSuccess: "Product removed successfully."
    },
    module: {
        createdSuccess: "Module created successfully.",
        updatedSuccess: "Module updated successfully.",
        deletedSuccess: "Module deleted successfully.",
        revertedSuccess: "Module reverted successfully.",
        alreadyExist: "Module name already exist.",
        recordNotFound: "Module not Found."
    },
    category: {
        createdSuccess: "Category created successfully.",
        updatedSuccess: "Category updated successfully.",
        deletedSuccess: "Category deleted successfully.",
        revertedSuccess: "Category reverted successfully.",
        alreadyExist: "Category name already exist.",
        recordNotFound: "Category not Found."
    },
    subCategory: {
        createdSuccess: "Sub category created successfully.",
        updatedSuccess: "Sub category updated successfully.",
        deletedSuccess: "Sub category deleted successfully.",
        revertedSuccess: "Sub category reverted successfully.",
        alreadyExist: "Sub category name already exist.",
        recordNotFound: "Sub category not Found."
    },
    product: {
        createdSuccess: "Product created successfully.",
        updatedSuccess: "Product updated successfully.",
        deletedSuccess: "Product deleted successfully.",
        revertedSuccess: "Product reverted successfully.",
        alreadyExist: "Product name already exist.",
        recordNotFound: "Product not Found.",
        requestAccepted: "Product request accepted successfully.",
        requestRejected: "Product request rejected successfully."
    },
    productImage: {
        createdSuccess: "Product image uploaded successfully.",
        deletedSuccess: "Product image deleted successfully.",
        alreadyExist: "Label name already exist.",
        recordNotFound: "Product image data not Found."
    },
    payment: {
        createdSuccess: "Payment details added successfully.",
        updatedSuccess: "Payment details updated successfully.",
        deletedSuccess: "Payment details deleted successfully.",
        revertedSuccess: "Payment details reverted successfully.",
        recordNotFound: "Payment not Found."
    }
}