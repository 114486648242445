import http from "../configuration/axios";
import { apiRoute } from "../utils/apiroutes";

export class DashboardService {
    static async getDashboardCount() {
        try {
            return (await http.get(`${apiRoute.dashboard.getDashboardCount}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }
}