import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../ui/Pagination";
import Loader from "../../ui/Loader";
import DeleteConfirmationModal from "../../ui/DeleteConfirmationModal";
import { toast } from "react-toastify";
import { messages } from "../../../utils/messages";
import { ProductService } from "../../../services/ProductService";
import AddProductImage from "./AddProductImage";
import { environment } from "../../../environment/environment";

const ProductImagesList = () => {
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [showProductImageModal, setShowProductImageModal] = useState<boolean>(false);
    const [prodcutImageList, setProdcutImageList] = useState<any[]>([]);
    const [selectedProductImage, setSelectedProductImage] = useState<any>(null);
    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState<any>(10);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [pageNo, setPageNo] = useState<any>(1);
    const navigate = useNavigate();

    const handleDeleteConfirmationModal = (flag: boolean, product?: any) => { 
        setSelectedProductImage(product || null);
        setOpenDeleteConfirmationModal(flag);
      };

      const handleProductImageModal = (flag: boolean) => { 
        setShowProductImageModal(flag);
      };
    
    
      const handleProductImageModalSubmit = () => { 
        setShowProductImageModal(false);
        getProductImageList();
      };
    
      useEffect(()=>{
        getProductImageList();
      },[pageNo])
    
      const getProductImageList = async () => {
        try {
          setLoader(true);
          const response: any = await ProductService.getAllImagesList(pageNo, pageSize, searchText);
          if (response?.status === 200) {
            setProdcutImageList(response?.data?.data?.content || []);
            setTotalCount(response?.data?.data?.totalCount);
            setLoader(false);
          } else {
            handleRequestError();
          }
        } catch (error) {
          handleRequestError();
        }
      };
    
      const handleRequestError = () => {
        setLoader(false);
        toast.error(messages.error);
      };
    
      const deleteProductImage = async (id: any) => {
        try {
          setLoader(true);
          const response = await ProductService.removeProductImages(id);
          if (response?.status === 200) {
            setLoader(false);
            toast.success(messages.productImage?.deletedSuccess);
            setOpenDeleteConfirmationModal(false);
            setSelectedProductImage(null);
            getProductImageList();
          } else {
            handleRequestError();
          }
        } catch (error) {
          handleRequestError();
        }
      };
    
      const handlePageChange = (data: any) => {
        setPageNo(data);
      }

      const handleApplyFilters = () => {
        setPageNo(1);
        getProductImageList();
      }
    
      const handleResetFilter = async () => {
        setPageNo(1);
        setSearchText(''); 
        setLoader(true);
        const response: any = await ProductService.getAllImagesList(1, pageSize);
        if (response?.status === 200) {
          setProdcutImageList(response?.data?.data?.content || []);
          setTotalCount(response?.data?.data?.totalCount);
          setLoader(false);
        } else {
          handleRequestError();
        }
      };

    return (
       <>
          <section>
            <div className='flex flex-row justify-between'>
              <div>
                <h2 className='font-semibold'>Product Images</h2>
                <div className='text-gray-400 text-sm'>Product Images{'>'} Product images list</div>
              </div>
              <div>
              <button type="button" onClick={ () => handleProductImageModal(true) } className="text-white bg-primary hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5">Add New</button>
              </div>
            </div>      
            <div className='bg-white rounded-lg p-4 mt-4'>
            <div className='w-full mb-5 flex justify-between'>
                <div className='flex'>
                  <div className='w-[350px]'>
                    <div className="relative w-full">
                      <i className="absolute top-[15px] left-[10px] lni lni-search-alt text-gray-500"></i>
                      <input className="w-full my-[3px] py-[8px] ps-[35px] pe-[16px] border border-[#dbdfe9] rounded-[8px]"
                        type="text" placeholder="Search by image label..." value={searchText} onChange={ (event) => { setSearchText(event.target.value) } }
                      />
                    </div>
                  </div>
                </div>
                <div>
                    <button onClick={ handleApplyFilters } disabled={!searchText} className='px-[25px] py-[10px] bg-secondary text-white text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300 disabled:text-white'>Apply</button>
                    <button onClick={ handleResetFilter } disabled={!searchText} className='px-[25px] py-[10px] bg-slate-200 text-black text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300 disabled:text-white'>Reset</button>
                </div>
              </div>
              <div className="relative overflow-x-auto">
                  <table className="w-full">
                      <thead className="text-sm text-slate-400 uppercase border-b-2 border-dashed border-slate-200 text-left">
                          <tr>
                              <th scope="col" className="px-4 py-3 w-[40%] font-semibold">
                                Image label
                              </th>
                              <th scope="col" className="px-4 py-3 w-[50%] font-semibold">
                                Image Name
                              </th>
                              <th scope="col" className="px-4 py-3 w-[10%] font-semibold">
                                  Action
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                          { (prodcutImageList?.length > 0) ? (prodcutImageList?.map((product: any) => (
                            <tr key={product?.id} className="bg-white text-md border-b border-slate-200 text-slate-900">
                                <td className="px-4 py-3 whitespace-nowrap">
                                    <div className="flex flex-row items-center">
                                      <div className="w-[70px] h-[50px] bg-gray-100 rounded-md me-3">
                                        <img className="w-[70px] h-[50px] object-center object-cover" src={environment.baseURL+product?.imageurl} alt={product?.label} />
                                      </div>
                                      <p>{product?.label}</p>
                                    </div>
                                </td>
                                <td className="px-4 py-3">
                                    {product?.filename}
                                </td>
                                <td className="px-4 py-3">
                                  <div className='flex'>
                                    <button onClick={ () => handleDeleteConfirmationModal(true, product) } className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-red-600 hover:ring-2 hover:ring-red-200'><i className="lni lni-trash-can"></i></button>
                                  </div>
                                </td>
                            </tr>
                          ))) : (
                            <tr><td colSpan={5} className='py-8 text-gray-500 text-center'>Product images data not found.</td></tr>
                          )}
                      </tbody>
                  </table>
                  <Pagination totalCount={totalCount} pageSize={pageSize} onPageChange={ (data) => { handlePageChange(data) } }/>
              </div>
            </div>
            { loader && <Loader/> }
            { showProductImageModal && <AddProductImage onClose={() => handleProductImageModal(false)} onSubmit={ handleProductImageModalSubmit }  /> }
            { openDeleteConfirmationModal && <DeleteConfirmationModal hide={ () => { handleDeleteConfirmationModal(false) }}  deleteConfirmation={(id: any) => deleteProductImage(id)} id={selectedProductImage?.id}/> }
          </section>
       </> 
    )
}

export default ProductImagesList;