import http from "../configuration/axios";
import { apiRoute } from "../utils/apiroutes";

export class ProductService {
    static async getProductList(pageNo?: any, pageSize?:any, search?: any, moduleId?:any, category?: any) {
        try {
            let url = `${apiRoute.product.getProduct}`;
            if(pageNo && pageSize){
                url = url + `?pageSize=${pageSize}&pageNo=${pageNo}`;
            }

            if(search?.trim()){
                url = url + `&search=${search}`;
            }

            if(moduleId){
                url = url + `&module=${moduleId}`;
            }

            if(category){
                url = url + `&category=${category}`;
            }

            return (await http.get(url, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async getProductById(id: any) {
        try {
            return (await http.get(`${apiRoute.product.gettProductById}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async saveProduct(data: any) {
        try {
            return (await http.post(`${apiRoute.product.createProduct}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async updateProduct(data: any) {
        try {
            return (await http.put(`${apiRoute.product.updateProduct}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async deleteProduct(id: any) {
        try {
            return (await http.delete(`${apiRoute.product.deleteProduct}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async revertProduct(id: any) {
        try {
            return (await http.put(`${apiRoute.product.revertProduct}${id}`, {}, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async getProductImages(id: any) {
        try {
            return (await http.get(`${apiRoute.product.productImages}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async getProductMethod() {
        try {
            return (await http.get(`${apiRoute.product.productMethod}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async getAllImagesList(pageNo?: any, pageSize?:any, search?:any) {
        try {
            let url = `${apiRoute.product.getAllProductImage}`;
            
            if(pageNo && pageSize){
                url = url + `?pageSize=${pageSize}&pageNo=${pageNo}`
            }

            if(search?.trim()){
                url = url + `&search=${search}`;
            }

            return (await http.get(url, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async removeProductImages(id: any) {
        try {
            return (await http.delete(`${apiRoute.product.removeProductImage}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async uploadProductImages(formData: any) {
        try {
            return (await http.post(`${apiRoute.product.uploadProductImage}`, formData, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                  'content-type': 'multipart/form-data'
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async getRequestedProductsList(pageNo?: any, pageSize?:any, search?: any) {
        try {
            let url = `${apiRoute.product.requestedProducts}`;
            if(pageNo && pageSize){
                url = url + `?pageSize=${pageSize}&pageNo=${pageNo}`;
            }

            if(search?.trim()){
                url = url + `&search=${search}`;
            }

            return (await http.get(url, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async rejectProductRequest(id: any) {
        try {
            return (await http.delete(`${apiRoute.product.rejectProductRequest}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }
}