import React, { useEffect, useState } from 'react';
import AddSubCategory from './AddSubCategory';
import DeleteConfirmationModal from '../ui/DeleteConfirmationModal';
import { SubCategoryService } from '../../services/SubCategoryService';
import { toast } from 'react-toastify';
import { messages } from '../../utils/messages';
import Loader from '../ui/Loader';
import StatusBadge from '../ui/StatusBadge';
import Pagination from '../ui/Pagination';
import RevertConfirmationModal from '../ui/RevertConfirmationModal';
import { CategoryService } from '../../services/CategoryService';

const SubCategoryList = () => {
  const [showSubCategoryModal, setShowSubCategoryModal] = useState<boolean>(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [openRevertConfirmationModal, setOpenRevertConfirmationModal] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [subCategoryList, setSubCategoryList] = useState<any[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any | null>(null);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [pageNo, setPageNo] = useState<any>(1);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [categoryId, setCategoryId] = useState('');
  const [searchText, setSearchText] = useState('');

  const handleSubCategoryModal = (flag: boolean, category?:any) => { 
    setIsEditMode(!!category);
    setSelectedSubCategory(category || null);
    setShowSubCategoryModal(flag)
  };

  const handleSubCategorySubmit = () => { 
    setShowSubCategoryModal(false);
    setSelectedSubCategory(null);
    getSubCategoryList();
  };

  const handleDeleteConfirmationModal = (flag: boolean, category?:any) => { 
    setSelectedSubCategory(category || null);
    setOpenDeleteConfirmationModal(flag) 
  };

  const handleRevertConfirmationModal = (flag: boolean, category?:any) => { 
    setSelectedSubCategory(category || null);
    setOpenRevertConfirmationModal(flag) 
  };

  useEffect(()=>{
    getSubCategoryList();
  },[pageNo])

  const getSubCategoryList = async () => {
    try {
      setLoader(true);
      const response: any = await SubCategoryService.getSubCategoryList(pageNo, pageSize, searchText, categoryId);
      if (response?.status === 200) {
        setSubCategoryList(response?.data?.data?.content || []);
        setTotalCount(response?.data?.data?.totalCount);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handlePageChange = (data: any) => {
    setPageNo(data);
  }

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  const deleteSubCategory = async (id: any) => {
    try {
      setLoader(true);
      const response = await SubCategoryService.deleteSubCategory(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.category?.deletedSuccess);
        setOpenDeleteConfirmationModal(false);
        setSelectedSubCategory(null);
        getSubCategoryList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const revertSubCategory = async (id: any) => {
    try {
      setLoader(true);
      const response = await SubCategoryService.revertSubCategory(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.category?.revertedSuccess);
        setOpenRevertConfirmationModal(false);
        setSelectedSubCategory(null);
        getSubCategoryList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  useEffect(() => {
    getCategoryList();
  }, [])

  const getCategoryList = async () => {
    try {
      setLoader(true);
      const response: any = await CategoryService.getCategoryList();
      if (response?.status === 200) {
        let data = response?.data?.data?.content?.filter((el: any) => el.status == 1);
        setCategoryList(data || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handleApplyFilters = () => {
    setPageNo(1);
    getSubCategoryList();
  }

  const handleResetFilter = async () => {
    setPageNo(1);
    setCategoryId('');
    setSearchText(''); 
    setLoader(true);
      const response: any = await SubCategoryService.getSubCategoryList(1, pageSize);
      if (response?.status === 200) {
        setSubCategoryList(response?.data?.data?.content || []);
        setTotalCount(response?.data?.data?.totalCount);
        setLoader(false);
      } else {
        handleRequestError();
      }
  };

  return (
    <section>
      <div className='flex flex-row justify-between'>
        <div>
          <h2 className='font-semibold'>Sub Category</h2>
          <div className='text-gray-400 text-sm'>Sub category {'>'} Sub category list</div>
        </div>
        <div>
        <button type="button" onClick={() => handleSubCategoryModal(true)} className="text-white bg-primary hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5">Add New</button>
        </div>
      </div>      
      <div className='bg-white rounded-lg p-4 mt-4'>
        <div className='w-full mb-5 flex justify-between'>
          <div className='flex'>
            <div className='w-[350px]'>
              <div className="relative w-full">
                <i className="absolute top-[15px] left-[10px] lni lni-search-alt text-gray-500"></i>
                <input className="w-full my-[3px] py-[8px] ps-[35px] pe-[16px] border border-[#dbdfe9] rounded-[8px]"
                  type="text" placeholder="Search by product name..." value={searchText} onChange={ (event) => { setSearchText(event.target.value) } }
                />
              </div>
            </div>
            <div className="form-control w-[300px] ms-4">
              <select name='categoryId' value={categoryId} onChange={(event) => { setCategoryId(event.target.value) }}>
                <option value="" hidden>Select category</option>
                {categoryList?.map((category: any) => (
                  <option key={category?.id} value={category?.id}>{category?.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div>
              <button onClick={ handleApplyFilters } disabled={!searchText && !categoryId} className='px-[25px] py-[10px] bg-secondary text-white text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300'>Apply</button>
              <button onClick={ handleResetFilter } disabled={!searchText && !categoryId} className='px-[25px] py-[10px] bg-slate-200 text-black text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300 disabled:text-white'>Reset</button>
          </div>
        </div>
        <div className="relative overflow-x-auto">
            <table className="w-full">
                <thead className="text-sm text-slate-400 uppercase border-b-2 border-dashed border-slate-200 text-left">
                    <tr>
                        <th scope="col" className="px-4 py-3 w-[40%] font-semibold">
                          Sub Category Name
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          Category
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          Module
                        </th>
                        <th scope="col" className="px-4 py-3 w-[15%] font-semibold">
                          Status
                        </th>
                        <th scope="col" className="px-4 py-3 w-[10%] font-semibold">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { (subCategoryList?.length > 0) ? (subCategoryList?.map((subCategory: any) => (
                      <tr key={subCategory?.id} className="bg-white text-md border-b border-slate-200 text-slate-900">
                        <td className="px-4 py-3 whitespace-nowrap">
                          {subCategory?.name}
                        </td>
                        <td className="px-4 py-3">
                          {subCategory?.category_name}
                        </td>
                        <td className="px-4 py-3">
                          {subCategory?.module_name}
                        </td>
                        <td className="px-4 py-3">
                            <StatusBadge status={subCategory?.status}/>
                        </td>
                        <td className="px-4 py-3">
                          <div className='flex'>
                            <button onClick={() => handleSubCategoryModal(true, subCategory)} className='text-[18px] flex justify-center items-center me-4 text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-primary hover:ring-2 hover:ring-blue-200'><i className="lni lni-pencil-alt"></i></button>
                            { (subCategory.status == 1)? 
                              (<button onClick={() => handleDeleteConfirmationModal(true, subCategory)} className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-red-600 hover:ring-2 hover:ring-red-200'><i className="lni lni-trash-can"></i></button>) :
                              (<button onClick={ () => handleRevertConfirmationModal(true, subCategory) }  className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-indigo-600 hover:ring-2 hover:ring-indigo-200 me-4'><i className="lni lni-reload"></i></button>)
                            }
                          </div>
                        </td>
                    </tr>
                    ))) : (
                      <tr><td colSpan={5} className='py-8 text-gray-500 text-center'>Sub category data not found.</td></tr>
                    )}
                </tbody>
            </table>
            <Pagination totalCount={totalCount} pageSize={pageSize} onPageChange={ (data) => { handlePageChange(data) } }/>
        </div>
      </div>
      { loader && <Loader/> }
      { showSubCategoryModal && <AddSubCategory editMode={isEditMode} subCategoryData={selectedSubCategory} onSubmit={ handleSubCategorySubmit } onClose={() => handleSubCategoryModal(false)}/> }
      { openDeleteConfirmationModal && <DeleteConfirmationModal hide={ () => { handleDeleteConfirmationModal(false) } } deleteConfirmation={(id: any) => deleteSubCategory(id)} id={selectedSubCategory?.id}/> }
      { openRevertConfirmationModal && <RevertConfirmationModal hide={ () => { handleRevertConfirmationModal(false) } } revertConfirmation={(id: any) => revertSubCategory(id)} id={selectedSubCategory?.id}/> }
    </section>
  );
};

export default SubCategoryList;
