import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from '../ui/Modal';
import { ProductService } from '../../services/ProductService';
import { messages } from '../../utils/messages';
import { toast } from 'react-toastify';
import { ModuleService } from '../../services/ModuleService';
import { CategoryService } from '../../services/CategoryService';
import { SubCategoryService } from '../../services/SubCategoryService';
import Loader from '../ui/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { environment } from '../../environment/environment';
import RichTextEditor from '../ui/RichTextEditor';
import SelectDropdown from '../ui/SelectDropdown';


const AddProduct = ({ onClose }: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [productId, setProductId] = useState<any>('');
  const [productName, setProductName] = useState('');
  const [productType, setProductType] = useState('');
  const [imageInput, setImageInput] = useState('');
  const [productMethod, setProductMethod] = useState('');
  const [prodcutMethodList, setProdcutMethodList] = useState<any[]>([]);
  const [content, setContent] = useState<any>(undefined);
  const [moduleId, setModuleId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [moduleList, setModuleList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<any[]>([]);
  const [prodcutImageList, setProdcutImageList] = useState<any[]>([]);
  const [prodcutImageOptionList, setProdcutImageOptionList] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [selectedProductImages , setSelectedProductImages] = useState<any>([]);

  const navigate = useNavigate();
  let params = useParams();
 
  useEffect(() => {
    setProductId(params.id);
  }, [params.id]);

  useEffect(() => {
    if (productId) {
      getProductById(productId);
      getProductImages(productId);
      setEditMode(true);
    }
  }, [productId]);

  useEffect(() => {
    getProductMethodList();
  }, []);

  const onEditorStateChange = (newEditorState: any) => {
    setContent(newEditorState);
  };

  const getProductMethodList = async () => {
    try {
      setLoader(true);
      const response: any = await ProductService.getProductMethod();
      if (response?.status === 200) {
        setProdcutMethodList(response?.data?.data || []);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(messages.error);
      }
    } catch (error) {
      setLoader(false);
      toast.error(messages.error);
    }
  };

  const getProductById = async (id: any) => {
    try {
      setLoader(true);
      const response: any = await ProductService.getProductById(id);
      if (response?.status === 200) {
        let productData = response?.data?.data;
        setProductName(productData?.name || '');
        setContent(productData?.content || '');
        setModuleId(productData?.module_id || '');
        setCategoryId(productData?.category_id || '');
        setSubCategoryId(productData?.sub_category_id || '');
        setProductType(productData?.pay_status || '');
        if(productData?.module_id){
          getCategoryList(productData?.module_id)
        }
        if(productData?.category_id){
          getSubCategoryList(productData?.category_id)
        }
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  }

  const validateForm = () => {
    let errors: any = {};
    let isValid = true;

    if (!productName.trim()) {
      errors.productName = 'Product name is required.';
      isValid = false;
    }

    if (!content.replace(/<[^>]+>/g, '').trim()) {
      errors.content = 'Content is required.';
      isValid = false;
    }

    if (!productType) {
      errors.productType = 'Type is required.';
      isValid = false;
    }


    if (!moduleId) {
      errors.moduleId = 'Module is required.';
      isValid = false;
    }

    if (!categoryId) {
      errors.categoryId = 'Category is required.';
      isValid = false;
    }

    if (!subCategoryId) {
      errors.subCategoryId = 'Sub category is required.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { 
          name : productName, 
          content : content, 
          sub_category_id : subCategoryId, 
          pay_status : productType,
          productImages: selectedProductImages.map((image: any) => { return {image_id: image.id, method_id: image?.method_id}}),
          status : 1 
        };
        const response: any = await ProductService.saveProduct(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.product?.createdSuccess);
          navigate("/app/products");
        } else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.product?.alreadyExist);
        } else {
          setLoader(false);
          toast.error(messages.error);
        }
      } catch (error) {
        console.error("Error occurred", error);
      }
    }
  };

  const handleUpdate = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { 
          id: productId,
          name : productName, 
          content : content, 
          sub_category_id : subCategoryId, 
          productImages: selectedProductImages.map((image: any) => { return {image_id: image.id, method_id: image?.method_id}}),
          pay_status : productType
        };
        const response: any = await ProductService.updateProduct(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.product?.createdSuccess);
          navigate("/app/products");
        } else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.product?.alreadyExist);
        } else {
          setLoader(false);
          toast.error(messages.error);
        }
      } catch (error) {
        console.error("Error occurred", error);
      }
    }
  };

  useEffect(() => {
    getModuleList();
    getProductImageList();
  },[])

  const getModuleList = async () => {
    try {
      setLoader(true);
      const response: any = await ModuleService.getModuleList();
      if (response?.status === 200) {
        setModuleList(response?.data?.data?.content || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const getCategoryList = async (id: any) => {
    try {
      setLoader(true);
      const response: any = await CategoryService.getCategoryListByModuleId(id);
      if (response?.status === 200) {
        setCategoryList(response?.data?.data || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const getProductImages = async (id: any) => {
    try {
      setLoader(true);
      const response: any = await ProductService.getProductImages(id);
      if (response?.status === 200) {
        setSelectedProductImages(response?.data?.data || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const getSubCategoryList = async (id: any) => {
    try {
      setLoader(true);
      const response: any = await SubCategoryService.getSubCategoryListByCategoryId(id);
      if (response?.status === 200) {
        setSubCategoryList(response?.data?.data || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const getProductImageList = async () => {
    try {
      setLoader(true);
      const response: any = await ProductService.getAllImagesList();
      if (response?.status === 200) {
        let data = response?.data?.data?.content?.map((el: any) => { return { id: el?.id, name: el?.label } })
        setProdcutImageList(response?.data?.data?.content || []);
        setProdcutImageOptionList(data || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handleImageSelection = () => {
    if (!imageInput) {
        return;
    }

    let selectedImageIds = selectedProductImages?.filter((x: any) => Number(x.id) === Number(imageInput));
    if (selectedImageIds.length > 0) {
        toast.warning('Selected image already added.');
        return;
    }

    const selectedImageData = prodcutImageList?.filter((x) => Number(x.id) === Number(imageInput));
    
    if (selectedImageData.length === 0) {
        return;
    }

    let selectedData: any = selectedImageData[0];
    
    if(productMethod){
      let method = prodcutMethodList?.filter((x) => Number(x.id) === Number(productMethod))?.[0];
      selectedData.method_id = method?.id;
      selectedData.method_name = method?.name
    }

    setSelectedProductImages([...selectedProductImages, selectedData]);
    setImageInput('');
    setProductMethod('');
  };

  const removeProductImage = async (id: any) => {
    const selectedImageData = selectedProductImages.filter((x: any) => Number(x.id) !== Number(id));
    setSelectedProductImages(selectedImageData);
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  const handleImgSelectionChange = (id: any) => {
    setImageInput(id);
  }

  return (
      <section className='flex flex-row flex-wrap w-full'>
        <div className='flex flex-row justify-between w-full'>
          <div>
            <h2 className='font-semibold'>{editMode? 'Edit':'Add'} Product</h2>
            <div className='text-gray-400 text-sm'>Product {'>'} {editMode? 'Edit':'Add'} Product</div>
          </div>
          <div>
          { !editMode? 
            (<button type="button" onClick={ handleSubmit } className="text-white bg-primary hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5">Save Product</button>):
            (<button type="button" onClick={ handleUpdate } className="text-white bg-primary hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5">Update Product</button>)
          }
          </div>
        </div> 
        <div className='w-full bg-white rounded-lg p-4 mt-4'>
            <h2 className='font-semibold text-[18px]'>Category Information</h2>
            <div className='mt-4 flex flex-row gap-4'>
                <div className="form-control w-full">
                  <label htmlFor="user">Module</label>
                  <select name='moduleId' value={moduleId} onChange={(event) => { setModuleId(event.target.value); getCategoryList(event.target.value)}}>
                    <option value="" hidden>Select module</option>
                    {moduleList?.map((module: any) => (
                      <option key={module?.id} value={module?.id}>{module?.name}</option>
                    ))}
                  </select>
                  {errors?.moduleId && <span className="text-red-600 text-sm ms-2 font-medium">{errors.moduleId}</span>}
                </div>
                <div className="form-control w-full">
                  <label htmlFor="user">Category</label>
                  <select name='categoryId' value={categoryId} onChange={(event) => { setCategoryId(event.target.value); getSubCategoryList(event.target.value)}}>
                    <option value="" hidden>Select category</option>
                    {categoryList?.map((category: any) => (
                      <option key={category?.id} value={category?.id}>{category?.name}</option>
                    ))}
                  </select>
                  {errors?.categoryId && <span className="text-red-600 text-sm ms-2 font-medium">{errors.categoryId}</span>}
                </div>
                <div className="form-control w-full">
                  <label htmlFor="user">Sub Category</label>
                  <select name='subCategoryId' value={subCategoryId} onChange={(event) => { setSubCategoryId(event.target.value);}}>
                    <option value="" hidden>Select sub category</option>
                    {subCategoryList?.map((subcategory: any) => (
                      <option key={subcategory?.id} value={subcategory?.id}>{subcategory?.name}</option>
                    ))}
                  </select>
                  {errors?.subCategoryId && <span className="text-red-600 text-sm ms-2 font-medium">{errors.subCategoryId}</span>}
                </div>
            </div>
        </div>
        <div className='w-full bg-white rounded-lg p-4 mt-4'>
            <div>
              <h2 className='font-semibold text-[18px]'>Product Information</h2>
              <div className='mt-4'>
                <div className="form-control">
                  <label htmlFor="user">Product Name</label>
                  <input type="text" placeholder="Enter product name" value={productName} onChange={(event) => { setProductName(event.target.value);}} />
                  {errors?.productName && <span className="text-red-600 text-sm ms-2 font-medium">{errors.productName}</span>}
                </div>
                <div className="form-control">
                  <label htmlFor="user">Content</label>
                  <RichTextEditor initialValue={content} placeholder='Enter content' onEditorChange={onEditorStateChange}/>
                  {/* <textarea name="content" rows={8} placeholder='Enter content' value={content} onChange={(event) => { setContent(event.target.value);}}></textarea> */}
                  {errors?.content && <span className="text-red-600 text-sm ms-2 font-medium">{errors.content}</span>}
                </div>
                <div className='flex flex-row'>
                  <div className="form-control w-[400px] me-5">
                    <label htmlFor="user">Product Type</label>
                    <select name="productType" value={productType}  onChange={(event) => { setProductType(event.target.value);}}>
                      <option value="" hidden> Select type</option>
                      <option value="1">Paid</option>
                      <option value="2">Free</option>
                    </select>
                    {errors?.productType && <span className="text-red-600 text-sm ms-2 font-medium">{errors.productType}</span>}
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className='w-full'>
          <div className='bg-white rounded-lg p-4 mt-4'>
              <h2 className='font-semibold text-[18px]'>Product Media</h2>
              <div className='flex flex-row'>
                <div className="form-control w-[400px] me-3">
                      
                    <label htmlFor="user">Product Image</label>
                    {/* <select name="productImage" value={imageInput}  onChange={(event) => { setImageInput(event.target.value);}}>
                      <option value="" hidden> Select image</option>
                      {prodcutImageList?.map((image: any) => (<option key={image?.id} value={image?.id}>{image?.label}</option>))}
                    </select> */}
                    <SelectDropdown placeHolder={'Select image'} value={imageInput} options={prodcutImageOptionList} selectionChange={(event: any) => handleImgSelectionChange(event)}/>
                    {errors?.imageInput && <span className="text-red-600 text-sm ms-2 font-medium">{errors.imageInput}</span>}
                </div>
                <div className="form-control w-[400px]">
                  <label htmlFor="user">Method</label>
                  <select name="productMethod" value={productMethod}  onChange={(event) => { setProductMethod(event.target.value);}}>
                    <option value="" hidden> Select method</option>
                    {prodcutMethodList?.map((method: any) => (<option key={method?.id} value={method?.id}>{method?.name}</option>))}
                  </select>
                  {errors?.productMethod && <span className="text-red-600 text-sm ms-2 font-medium">{errors.productMethod}</span>}
                </div>
                <div>
                  <button type="button" disabled={!imageInput} onClick={ handleImageSelection } className="text-white ms-3 mt-6 h-[44px] bg-secondary focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 disabled:bg-gray-300">Add Image</button>
                </div>
              </div>
              <div className='mt-4 flex gap-3 flex-row flex-wrap'>
                { selectedProductImages?.map((image: any, index: any) => (
                  <div key={index} className='w-[200px] bg-slate-100 rounded-lg p-[5px] flex relative me-2'>
                    <div className='min-w-[70px] w-[70px] h-[70px] rounded-md border-[2px] bg-slate-400'>
                      <img className='object-cover w-full h-full rounded' src={environment.baseURL+image?.imageurl} alt={image?.filename} />
                    </div>
                    <div className='ms-2 w-[110px]'>
                      <p className='font-medium truncate'>{image?.label}</p>
                      <p className='text-gray-600 text-sm'>{image?.method_name}</p>
                    </div>
                    <div onClick={ () => removeProductImage(image?.id) } className='absolute right-[-5px] top-[-5px] bg-white rounded-full w-[20px] h-[20px] cursor-pointer ring-2 ring-slate-300 flex justify-center items-center text-[12px] transition ease-in duration-100 hover:text-red-500 hover:ring-red-300'><i className="lni lni-close text-[12px]"></i></div>
                  </div>
                ))}
              </div>
            </div>
        </div>
        { loader && <Loader/> }
      </section>
    );
  };

export default AddProduct;
