import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRoutes from './routes/AppRoutes';
import Toaster from './components/ui/Toaster';

function App() {
  return (
    <>
      <BrowserRouter>
          <AppRoutes/>
      </BrowserRouter>
      <Toaster/>
    </>
  );
}

export default App;
