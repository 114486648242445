const PayStatusBadge = ({ status }: any) => {
    return (
        <>
            { status === 1 && (
                <div className="px-3 py-[4px] ring-1 ring-emerald-400 bg-emerald-50 text-emerald-500 text-sm font-semibold w-fit rounded-md">PAID</div>
             )}
            { (status === 2 || status === 0) && (
                <div className="px-3 py-[4px] ring-1 ring-amber-400 bg-amber-50 text-amber-400 text-sm font-semibold w-fit rounded-md">FREE</div>
            )}
        </>
    )
}

export default PayStatusBadge;