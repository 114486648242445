import React, { useEffect, useState } from 'react';
import AddModule from './AddModule';
import DeleteConfirmationModal from '../ui/DeleteConfirmationModal';
import { ModuleService } from '../../services/ModuleService';
import Loader from '../ui/Loader';
import { toast } from 'react-toastify';
import { messages } from '../../utils/messages';
import StatusBadge from '../ui/StatusBadge';
import Pagination from '../ui/Pagination';
import RevertConfirmationModal from '../ui/RevertConfirmationModal';
import { environment } from '../../environment/environment';

interface Module {
  id: string;
  name: string;
  description: string,
  image: string;
  imageurl: string,
  status: any;
}

const ModuleList: React.FC = () => {
  const [showModuleModal, setShowModuleModal] = useState<boolean>(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState<boolean>(false);
  const [openRevertConfirmationModal, setOpenRevertConfirmationModal] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [moduleList, setModuleList] = useState<Module[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedModule, setSelectedModule] = useState<Module | null>(null);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [pageNo, setPageNo] = useState<any>(1);

  const handleModuleModal = (flag: boolean, module?: Module) => { 
    setIsEditMode(!!module);
    setSelectedModule(module || null);
    setShowModuleModal(flag);
  };

  const handleModuleModalSubmit = () => { 
    setShowModuleModal(false);
    getModuleList();
  };

  const handleDeleteConfirmationModal = (flag: boolean, module?: Module) => { 
    setSelectedModule(module || null);
    setOpenDeleteConfirmationModal(flag);
  };

  const handleRevertConfirmationModal = (flag: boolean, module?:any) => { 
    setSelectedModule(module || null);
    setOpenRevertConfirmationModal(flag) 
  };

  useEffect(() => {
    getModuleList();
  }, [pageNo]);

  const deleteModule = async (id: any) => {
    try {
      setLoader(true);
      const response = await ModuleService.deleteModule(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.module?.deletedSuccess);
        setOpenDeleteConfirmationModal(false);
        setSelectedModule(null);
        getModuleList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const revertModule = async (id: any) => {
    try {
      setLoader(true);
      const response = await ModuleService.revertModule(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.module?.revertedSuccess);
        setOpenRevertConfirmationModal(false);
        setSelectedModule(null);
        getModuleList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const getModuleList = async () => {
    try {
      setLoader(true);
      const response: any = await ModuleService.getModuleList(pageNo, pageSize);
      if (response?.status === 200) {
        setModuleList(response?.data?.data?.content || []);
        setTotalCount(response?.data?.data?.totalCount);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  const handlePageChange = (data: any) => {
    setPageNo(data);
  }

  return (
    <section>
      <div className='flex flex-row justify-between'>
        <div>
          <h2 className='font-semibold'>Module</h2>
          <div className='text-gray-400 text-sm'>Module {'>'} Module list</div>
        </div>
        <div>
          <button type="button" onClick={() => handleModuleModal(true)} className="text-white bg-primary hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5">Add New</button>
        </div>
      </div>
      <div className='bg-white rounded-lg p-4 mt-4'>
        <div className="relative overflow-x-auto">
          <table className="w-full">
            <thead className="text-sm text-slate-400 uppercase border-b-2 border-dashed border-slate-200 text-left">
              <tr>
                <th scope="col" className="px-4 py-3 w-[60%] font-semibold">
                  Module Name
                </th>
                <th scope="col" className="px-4 py-3 w-[60%] font-semibold">
                  Module Description
                </th>
                <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                  Status
                </th>
                <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              { (moduleList?.length > 0)? (
                moduleList.map((module: Module, index: number) => (
                  <tr key={module.id} className="bg-white text-md border-b border-slate-200 text-slate-900">
                    <td className="px-4 py-3 whitespace-nowrap">
                      <div className="flex flex-row items-center">
                        <div className="w-[70px] h-[50px] bg-gray-100 rounded-md me-3">
                          <img className="w-[70px] h-[50px] object-center object-cover rounded-md" src={environment.baseURL + module?.imageurl}
                            alt={module?.image}
                          />
                        </div>
                        <p>{module?.name}</p>
                      </div>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {module?.description?.length > 50 ? module?.description?.slice(0,50)+'...' : module?.description}
                    </td>
                    <td className="px-4 py-3">
                      <StatusBadge status={module.status}/>
                    </td>
                    <td className="px-4 py-3">
                      <div className='flex'>
                        <button onClick={() => handleModuleModal(true, module)} className='text-[18px] flex justify-center items-center me-4 text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-primary hover:ring-2 hover:ring-blue-200'><i className="lni lni-pencil-alt"></i></button>
                        { (module.status == 1)? 
                          (<button onClick={() => handleDeleteConfirmationModal(true, module)} className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-red-600 hover:ring-2 hover:ring-red-200'><i className="lni lni-trash-can"></i></button>) :
                          (<button onClick={ () => handleRevertConfirmationModal(true, module) }  className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-indigo-600 hover:ring-2 hover:ring-indigo-200 me-4'><i className="lni lni-reload"></i></button>)
                        }
                      </div>
                    </td>
                  </tr>
                ))
              ):(
                <tr><td colSpan={5} className='py-8 text-gray-500 text-center'>Module data not found.</td></tr>
              )}
            </tbody>
          </table>
          <Pagination totalCount={totalCount} pageSize={pageSize} onPageChange={ (data) => { handlePageChange(data) } }/>
        </div>
      </div>
      { loader && <Loader/> }
      { showModuleModal && <AddModule editMode={isEditMode} moduleData={selectedModule} onClose={() => handleModuleModal(false)} onSubmit={ handleModuleModalSubmit }  /> }
      { openRevertConfirmationModal && <RevertConfirmationModal hide={ () => { handleRevertConfirmationModal(false) } } revertConfirmation={(id: any) => revertModule(id)} id={selectedModule?.id}/> }
      { openDeleteConfirmationModal && <DeleteConfirmationModal hide={() => handleDeleteConfirmationModal(false)} deleteConfirmation={(id: any) => deleteModule(id)} id={selectedModule?.id} /> }
    </section>
  );
};

export default ModuleList;
