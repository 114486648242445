import React from 'react';

const RevertConfirmationModal = ({ hide, revertConfirmation, id }: any) => {

    return (
        <div className="fixed z-50 bg-black/50 inset-0 flex justify-center items-center">
            <div className="relative bg-white flex flex-col items-center rounded-2xl p-[max(2vw,1.5rem)] w-[30vw] h-auto max-h-[90%] overflow-y-auto">
                <div className='flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg>
                </div>
                <i className="lni lni-close absolute cursor-pointer top-[20px] right-[20px] hover:text-red-600" onClick={hide}></i>
                <h2 className="font-bold capitalize text-center text-[20px]">Confirm</h2>
                <p className="text-center">Are you sure you want to revert (Recover) this record?</p>
                <div className="mt-4 flex justify-center items-center">
                    <button type="button" onClick={hide} className="bg-stone-200 me-3 text-slate-500 rounded-lg py-2 px-5"> Cancel</button>
                    <button type="button" className="bg-red-500 text-white rounded-lg py-2 px-5" onClick={() => revertConfirmation(id)}> Revert</button>
                </div>
            </div>
        </div>
    )
}

export default RevertConfirmationModal;