import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { UserProvider } from '../../context/UserContext';

const Layout = ({ children }: any) => {
  return (
    <UserProvider>
      <div className="overflow-hidden">
        <div className="flex">
              <Sidebar />
              <div className="relative flex h-full max-w-full flex-1 flex-col">
                <Header />
                <div className='p-5 bg-[#f3f6f8] mt-[70px] h-[calc(100vh-70px)] max-h-[calc(100vh-70px)] overflow-auto'>
                    {children}
                </div>
              </div>
        </div>
      </div>
    </UserProvider>
  );
};

export default Layout;
