import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types'; 


const MultiSelectDropdown = ({ options,  selectionChange, placeHolder, selectedValue, disabled}: any) => {
    const [selectedValues, setSelectedValues] = useState<any>(selectedValue? selectedValue : []);
    const [optionList, setOptionList] = useState<any>(options);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef<any>(null);

    useEffect(() => {
        setOptionList(options);
    },[options])
    useEffect(() => {
        setSelectedValues(selectedValue);
    },[selectedValue])

    const handleValueSelection = (e: any, value: any) => {
        e.stopPropagation();
        setSelectedValues((prevValues: any) => {
            if (!prevValues.includes(value)) {
                selectionChange([...prevValues, value]);
                return [...prevValues, value];
            } else {
                selectionChange(prevValues.filter((el: any) => el !== value));
                return prevValues.filter((el: any) => el !== value);
            }
            
        });
    }

    const handleSearch = (e: any) => {
        const term = e.target.value;
        setSearchTerm(term);
        const filtered = options.filter((option: any) =>
          option?.name?.toLowerCase().includes(term.toLowerCase())
        );
        setOptionList(filtered);
    };

    const handleDropdownMethod = (e: any) => {
        e.stopPropagation();
        if(!disabled){
            setShowDropdown(!showDropdown);
        }
        
    }

    const handleSelectAll = () => {
        if(selectedValues.length == optionList?.length){
            setSelectedValues([]);
            selectionChange([]);
        }else{
            setSelectedValues((preValues: any) => {
                selectionChange(optionList.map((el: any) => el.id));
                return optionList.map((el: any) => el.id);
            });
        }
       
    }

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
                setSearchTerm('');
                // setOptionList(options);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={dropdownRef} className='relative w-[100%]'>
                <div className={`${disabled? 'bg-slate-100':'bg-white'} cursor-default rounded-[8px] border-[1px] p-2 border-[#D6D6D6] flex item-center justify-between`} onClick={(event) => { handleDropdownMethod(event) }}>
                    { selectedValues?.length == 0? 
                        (<span className='text-[#aaaaaa] text-[16px]'>{placeHolder? placeHolder:'Select'}</span>):
                        (<p className="truncate">
                            {selectedValues.map((selectedValue: any) => (
                                options.find((option: any) => option.id === selectedValue)?.name
                            )).join(", ")}
                        </p>)
                    }
                    {/* <i className='material-icons text-[18px] flex items-center'>expand_more</i> */}
                    <div className='flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[15px] h-[15px]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>

                </div>
                {showDropdown &&
                    (
                       (options?.length > 0) ? (
                       <div className="absolute transition duration-75 ease-in z-[9999] w-[100%] max-h-[300px] bg-white rounded-[8px] border-[1px] border-[#D6D6D6] shadow-lg overflow-auto scroll-smooth shadow-md">
                            <div className="px-3 pt-2 pb-1 sticky top-0 bg-white">
                                <div>
                                    <input type="text" placeholder='Search...' value={searchTerm} onChange={handleSearch} />
                                </div>
                            </div>
                            { (optionList?.length > 0) && (
                                    <div onClick={handleSelectAll} className="px-3 py-2 hover:bg-primary hover:text-white cursor-pointer flex flex-row justify-between">
                                        <span>Select All</span>
                                        {(selectedValues.length == options?.length) &&
                                            (<div className="w-[16px]">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd"></path></svg>
                                            </div>)
                                        }
                                    </div>
                                )
                            }
                            {optionList?.map((item: any, index: any) => (
                                <div key={index} onClick={(event) => { handleValueSelection(event, item?.id) }} className="px-3 py-2 hover:bg-primary hover:text-white cursor-pointer flex flex-row justify-between">
                                    <span>{item?.name}</span>
                                    {selectedValues.includes(item?.id) &&
                                        (<div className="w-[16px] min-w-[16px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd"></path></svg>
                                        </div>)
                                    }
                                </div>
                            ))}
                        </div>) : (
                            <div className="absolute transition duration-75 ease-in z-[999] w-[100%] max-h-[200px] bg-white rounded-[8px] border-[1px] border-[#D6D6D6] shadow-lg overflow-auto scroll-smooth shadow-md p-3">
                                <p className='text-slate-400 text-sm'>Data not found...</p>
                            </div>
                        )
                    )
                }
            </div>
        </>
    )
}

export default MultiSelectDropdown;

MultiSelectDropdown.propTypes = {
    selectionChange: PropTypes.any.isRequired,
    options: PropTypes.array.isRequired,
    placeHolder: PropTypes.any,
    selectedValue: PropTypes.any,
    disabled: PropTypes.any
};
