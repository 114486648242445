import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="w-[270px] sidebar bg-sidebar min-h-screen h-[100vh] border-e divide-inherit">
        <div className='px-6 py-4 flex'>
            <div><span className='text-[26px] font-[700] text-primary'>Meridian</span><span className='text-[26px] text-secondary'>guru</span></div>
        </div>
        <div className='p-3' style={{ height: 'calc(100vh - 72px)', overflow: 'auto' }}>
            <ul>
                <li className='mb-1'>
                    <NavLink to={'/app/dashboard'} className='flex group flex-row py-3 px-4 text-gray-700 font-normal rounded-[6px] transition duration-300 hover:bg-blue-50 hover:text-primary cursor-pointer'>
                        <div className='h-[10px] me-3'>
                            <svg className='stroke-gray-400 group-hover:stroke-primary group-hover:fill-blue-200' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 9v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9"/><path d="M9 22V12h6v10M2 10.6L12 2l10 8.6"/></svg>
                        </div>
                        <div>Dashboard</div>
                    </NavLink>
                </li>
                <li className='mb-1'>
                    <NavLink to={'/app/users'} className='group flex flex-row py-3 px-4 text-gray-700 font-normal rounded-[6px] transition duration-300 hover:bg-blue-50 hover:text-primary cursor-pointer'>
                        <div className='h-[10px] me-3'>
                            <svg className='stroke-gray-400 group-hover:stroke-primary group-hover:fill-blue-200' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                        </div>
                        <div>Users</div>
                    </NavLink>
                </li>
                <li className='mb-1'>
                    <NavLink to={'/app/module'} className='group flex flex-row py-3 px-4 text-gray-700 font-normal rounded-[6px] transition duration-300 hover:bg-blue-50 hover:text-primary cursor-pointer'>
                        <div className='h-[10px] me-3'>
                            <svg className='stroke-gray-400 group-hover:stroke-primary group-hover:fill-blue-200' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="2" y="4" width="20" height="16" rx="2"/><path d="M7 15h0M2 9.5h20"/></svg>
                        </div>
                        <div>Module</div>
                    </NavLink>
                </li>
                <li className='mb-1'>
                    <NavLink to={'/app/category'} className='group flex flex-row py-3 px-4 text-gray-700 font-normal rounded-[6px] transition duration-300 hover:bg-blue-50 hover:text-primary cursor-pointer'>
                        <div className='h-[10px] me-3'>
                            <svg className='stroke-gray-400 group-hover:stroke-primary group-hover:fill-blue-200' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="3" width="18" height="18" rx="2"/><path d="M3 9h18M9 21V9"/></svg>
                        </div>
                        <div>Category</div>
                    </NavLink>
                </li>
                <li className='mb-1'>
                    <NavLink to={'/app/sub-category'} className='group flex flex-row py-3 px-4 text-gray-700 font-normal rounded-[6px] transition duration-300 hover:bg-blue-50 hover:text-primary cursor-pointer'>
                        <div className='h-[10px] me-3'>
                            <svg className='stroke-gray-400 group-hover:stroke-primary group-hover:fill-blue-200' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="3" width="18" height="18" rx="2"/><path d="M3 9h18"/></svg>
                        </div>
                        <div>Sub Category</div>
                    </NavLink>
                </li>
                <li className='mb-1'>
                    <NavLink to={'/app/products'} className='group flex flex-row py-3 px-4 text-gray-700 font-normal rounded-[6px] transition duration-300 hover:bg-blue-50 hover:text-primary cursor-pointer'>
                        <div className='h-[10px] me-3'>
                            <svg className='stroke-gray-400 group-hover:stroke-primary group-hover:fill-blue-200' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                        </div>
                        <div>Product</div>
                    </NavLink>
                </li>
                <li className='mb-1'>
                    <NavLink to={'/app/product-images'} className='group flex flex-row py-3 px-4 text-gray-700 font-normal rounded-[6px] transition duration-300 hover:bg-blue-50 hover:text-primary cursor-pointer'>
                        <div className='h-[10px] me-3'>
                            <svg className='stroke-gray-400 group-hover:stroke-primary group-hover:fill-blue-200' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20" height="20"><path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" /></svg>
                        </div>
                        <div>Product Images</div>
                    </NavLink>
                </li>
                <li className='mb-1'>
                    <NavLink to={'/app/user-request'} className='group flex flex-row py-3 px-4 text-gray-700 font-normal rounded-[6px] transition duration-300 hover:bg-blue-50 hover:text-primary cursor-pointer'>
                        <div className='h-[10px] me-3'>
                            <svg className='stroke-gray-400 group-hover:stroke-primary group-hover:fill-blue-200' xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.25V18a2.25 2.25 0 0 0 2.25 2.25h13.5A2.25 2.25 0 0 0 21 18V8.25m-18 0V6a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6ZM7.5 6h.008v.008H7.5V6Zm2.25 0h.008v.008H9.75V6Z" />
                            </svg>
                        </div>
                        <div>User Request</div>
                    </NavLink>
                </li>
                <li className='mb-1'>
                    <NavLink to={'/app/payments'} className='group flex flex-row py-3 px-4 text-gray-700 font-normal rounded-[6px] transition duration-300 hover:bg-blue-50 hover:text-primary cursor-pointer'>
                        <div className='h-[10px] me-3'>
                            <svg className='stroke-gray-400 group-hover:stroke-primary group-hover:fill-blue-200' xmlns="http://www.w3.org/2000/svg"  width="20" height="20" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                            </svg>
                        </div>
                        <div>Payment</div>
                    </NavLink>
                </li>
                <li className='mb-1'>
                    <NavLink to={'/app/enquires'} className='group flex flex-row py-3 px-4 text-gray-700 font-normal rounded-[6px] transition duration-300 hover:bg-blue-50 hover:text-primary cursor-pointer'>
                        <div className='h-[10px] me-3'>
                            <svg className='stroke-gray-400 group-hover:stroke-primary group-hover:fill-blue-200' xmlns="http://www.w3.org/2000/svg"  width="20" height="20" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
                            </svg>
                        </div>
                        <div>Enquires</div>
                    </NavLink>
                </li>
            </ul>
        </div>
    </div>
  );
};

export default Sidebar;
