export const apiRoute: any = {
    auth: {
        login: 'oauth/login',
        userdetails: 'api/userdetails'
    },
    user : { 
        getUsers : 'api/user',
        createUser : 'api/user',
        updateUser : 'api/user',
        deleteUser : 'api/user/',
        revertUser : 'api/user/revert/',
        productByUserId: 'api/productByUserId/',
        deleteUsersProduct: 'api/deleteUsersProduct/'
    },
    module : {
        getModule : 'api/module',
        getModuleById : 'api/module/',
        createModule : 'api/module',
        updateModule : 'api/module',
        deleteModule : 'api/module/',
        revertModule : 'api/module/revert/',
        uploadModuleImage : 'api/module/imageupload'
    },
    category : {
        getCategory : 'api/category',
        getCategoryById : 'api/category/',
        createCategory : 'api/category',
        updateCategory : 'api/category',
        deleteCategory : 'api/category/',
        categoryByModule : 'api/categoryByModule',
        revertCategory : 'api/category/revert/'
    },
    subcategory : {
        getSubCategory : 'api/subcategory',
        gettSubCategoryById : 'api/subcategory/',
        createtSubCategory : 'api/subcategory',
        updateSubCategory : 'api/subcategory',
        deleteSubCategory : 'api/subcategory/',
        subcategoryByCategory: 'api/subcategoryByCategory',
        revertSubCategory : 'api/subcategory/revert/'
    },
    product : {
        getProduct : 'api/product',
        gettProductById : 'api/product/',
        createProduct : 'api/product',
        updateProduct : 'api/product',
        deleteProduct : 'api/product/',
        revertProduct : 'api/product/revert/',
        getAllProductImage : 'api/upload/productimage',
        uploadProductImage : 'api/upload/productimage',
        removeProductImage : 'api/upload/productimage/',
        productImages: 'api/productImages/',
        productMethod: 'api/productMethod',
        requestedProducts: 'api/requestedProducts',
        rejectProductRequest: 'api/rejectProductRequest/'
    },
    payment : {
        getPayment : 'api/payment',
        getPaymentById : 'api/payment/',
        createPayment : 'api/payment',
        updatePayment : 'api/payment',
        deletePayment : 'api/payment/',
        paymentMethod : 'api/paymentMethod/',
        revertPayment : 'api/payment/revert/'
    },
    dashboard : {
        getDashboardCount : 'api/dashboardCount'
    },
    enquiry : {
        getEnquiryList : 'api/enquiry',
        getEnquiryDetails : 'api/enquiry/',
        updateEnquiryDetails: 'api/enquiry',
    }
}