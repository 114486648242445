import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import { CategoryService } from "../../services/CategoryService";
import { toast } from "react-toastify";
import { messages } from "../../utils/messages";
import { SubCategoryService } from "../../services/SubCategoryService";
import Loader from "../ui/Loader";

const AddSubCategory = ({ onClose, onSubmit, editMode, subCategoryData  }: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [subCategoryName, setSubCategoryName] = useState(editMode? subCategoryData?.name : '');
  const [categoryId, setCategoryId] = useState(editMode? subCategoryData?.category_id : '');
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    let errors: any = {};
    let isValid = true;

    if (!subCategoryName.trim()) {
      errors.subCategoryName = 'Sub category name is required.';
      isValid = false;
    }

    if (!categoryId) {
      errors.categoryId = 'Category name is required.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    getCategoryList();
  }, [])

  const getCategoryList = async () => {
    try {
      setLoader(true);
      const response: any = await CategoryService.getCategoryList();
      if (response?.status === 200) {
        setCategoryList(response?.data?.data?.content || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { name: subCategoryName, category_id: categoryId, status: 1 };
        const response: any = await SubCategoryService.saveSubCategory(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.subCategory?.createdSuccess);
          onSubmit();
        } else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.subCategory?.alreadyExist);
        } else {
          handleRequestError();
        }
      } catch (error) {
        handleRequestError();
      }
    }
  };

  const handleUpdate = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { id: subCategoryData?.id, category_id: categoryId, name: subCategoryName };
        const response: any = await SubCategoryService.updateSubCategory(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.subCategory?.updatedSuccess);
          onSubmit();
        } else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.subCategory?.alreadyExist);
        } else {
          handleRequestError();
        }
      } catch (error) {
        handleRequestError();
      }
    }
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  return (
    <>
      <Modal>
        <div className="w-[30rem]">
          <div className="bg-white">
            <div className="px-4 py-4 flex flex-row justify-between">
              <h2 className="font-[600] text-[18px]">{editMode? 'Edit':'Add'} Sub Category</h2>
              <div>
                <i onClick={onClose} className="lni lni-close cursor-pointer font-[600] hover:text-red-600"></i>{" "}
              </div>
            </div>
            <div className="px-5 py-3">
              <div className="form-control">
                <label htmlFor="user">Sub Category Name</label>
                <input type="text" placeholder="Enter sub category name" name="subCategoryName" value={subCategoryName} onChange={(event) => { setSubCategoryName(event.target.value);}} />
                {errors?.subCategoryName && <span className="text-red-600 text-sm ms-2 font-medium">{errors.subCategoryName}</span>}
              </div>
              <div className="form-control">
                <label htmlFor="user">Category</label>
                <select name="categoryId" value={categoryId} onChange={(event) => { setCategoryId(event.target.value);}}>
                  <option value="" selected hidden>Select category</option>
                  { categoryList?.map((category: any) => (
                    <option key={category?.id} value={category?.id}>{category?.name}</option>
                  ))}
                </select>
                {errors?.categoryId && <span className="text-red-600 text-sm ms-2 font-medium">{errors.categoryId}</span>}
              </div>
            </div>
          </div>
          <div className="border-t-2 border-slate-100 px-4 py-3 flex justify-end">
            <button type="button" onClick={onClose} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"> Cancel </button>
            { !editMode ? 
              (<button type="button" onClick={ handleSubmit }  className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"> Submit </button>) :
              (<button type="button" onClick={ handleUpdate }  className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"> Update </button>)
            }
          </div>
        </div>
      </Modal>
      { loader && <Loader/> }
    </>
  );
};

export default AddSubCategory;
