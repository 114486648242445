import http from "../configuration/axios";
import { apiRoute } from "../utils/apiroutes";

export class UserService {
    static async getUserList(pageNo?: any, pageSize?:any, search?: any) {
        try {
            let url = `${apiRoute.user.getUsers}`;
            if(pageNo && pageSize){
                url = url + `?pageSize=${pageSize}&pageNo=${pageNo}`;
            }

            if(search?.trim()){
                url = url + `&search=${search}`;
            }

            return (await http.get(`${url}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async getUserProductList(id: any) {
        try {
            return (await http.get(`${apiRoute.user.productByUserId}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async saveUser(data: any) {
        try {
            return (await http.post(`${apiRoute.user.createUser}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async updateUser(data: any) {
        try {
            return (await http.put(`${apiRoute.user.updateUser}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async deleteUserProduct(id: any) {
        try {
            return (await http.delete(`${apiRoute.user.deleteUsersProduct}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async deleteUser(id: any) {
        try {
            return (await http.delete(`${apiRoute.user.deleteUser}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async revertUser(id: any) {
        try {
            return (await http.put(`${apiRoute.user.revertUser}${id}`, {}, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }
}