import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../components/authentication/Login';
import Layout from '../components/layout/Layout';
import Dashboard from '../components/dashboard/Dashboard';
import ModuleList from '../components/module/ModuleList';
import CategoryList from '../components/category/CategoryList';
import SubCategoryList from '../components/sub-category/SubCategoryList';
import ProductList from '../components/product/ProductList';
import UserList from '../components/user/UserList';
import AddProduct from '../components/product/AddProduct';
import PaymentList from '../components/payment/PaymentList';
import ProductImagesList from '../components/product/product-images-upload/ProductImagesList';
import PageNotFound from '../components/ui/PageNotFound';
import AuthGuard from '../guard/AuthGuard';
import EnquireList from '../components/enquire/EnquireList';
import RequestedProductList from '../components/requested-product/RequestedProductList';

function NotFound() {
  return <PageNotFound/>;
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="app/">   
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="dashboard" element={<AuthGuard><Layout><Dashboard /></Layout></AuthGuard>} />
        <Route path="module" element={<AuthGuard><Layout><ModuleList /></Layout></AuthGuard>} />
        <Route path="category" element={<AuthGuard><Layout><CategoryList /></Layout></AuthGuard>} />
        <Route path="sub-category" element={<AuthGuard><Layout><SubCategoryList /></Layout></AuthGuard>} />
        <Route path="products" element={<AuthGuard><Layout><ProductList /></Layout></AuthGuard>} />
        <Route path="products/add-product" element={<AuthGuard><Layout><AddProduct /></Layout></AuthGuard>} />
        <Route path="products/edit-product/:id" element={<AuthGuard><Layout><AddProduct /></Layout></AuthGuard>} />
        <Route path="product-images" element={<AuthGuard><Layout><ProductImagesList/></Layout></AuthGuard>} />
        <Route path="users" element={<AuthGuard><Layout><UserList /></Layout></AuthGuard>} />
        <Route path="payments" element={<AuthGuard><Layout><PaymentList /></Layout></AuthGuard>} />
        <Route path="enquires" element={<AuthGuard><Layout><EnquireList /></Layout></AuthGuard>} />
        <Route path="user-request" element={<AuthGuard><Layout><RequestedProductList /></Layout></AuthGuard>} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
