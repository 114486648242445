import React, { useState } from "react";
import Modal from "../ui/Modal";
import Loader from "../ui/Loader";
import { toast } from "react-toastify";
import { messages } from "../../utils/messages";
import { ModuleService } from "../../services/ModuleService";
import FileDragAndDrop from "../ui/FileDragAndDrop";

const AddModule = ({ onClose, onSubmit, editMode, moduleData }: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [moduleName, setModuleName] = useState(editMode? moduleData?.name : '');
  const [moduleDescription, setModuleDescription] = useState(editMode? moduleData?.description : '');
  const [image, setImage] = useState(editMode? moduleData?.image : '');
  const [imageurl, setImageurl] = useState(editMode? moduleData?.imageurl : '');
  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    let errors: any = {};
    let isValid = true;

    if (!moduleName.trim()) {
      errors.moduleName = 'Module is required.';
      isValid = false;
    }

    if (!moduleDescription.trim()) {
      errors.moduleDescription = 'Module description is required.';
      isValid = false;
    }

    if (!imageurl.trim()) {
      errors.imageurl = 'Module image is required.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { 
          name: moduleName,
          description: moduleDescription,
          image: image,
          imageurl: imageurl,
          status: 1 
        };

        const response: any = await ModuleService.saveModule(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.module?.createdSuccess);
          onSubmit();
        } else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.module?.alreadyExist);
        } else {
          setLoader(false);
          toast.error(messages.error);
        }
      } catch (error) {
        console.error("Error occurred", error);
      }
    }
  };

  const handleUpdate = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { 
          id: moduleData?.id,
          name: moduleName,
          description: moduleDescription,
          image: image,
          imageurl: imageurl,
        };
        const response: any = await ModuleService.updateModule(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.module?.updatedSuccess);
          onSubmit();
        } else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.module?.alreadyExist);
        } else {
          setLoader(false);
          toast.error(messages.error);
        }
      } catch (error) {
        console.error("Error occurred", error);
      }
    }
  };

  const handleImageUpload = async (imageData: any) => {
    try { 
      if(imageData){
        setLoader(true);
        const formData = new FormData();
        formData.append('image', imageData);
  
        const response: any = await ModuleService.uploadModuleImage(formData);
        if (response?.status === 200) {
          setLoader(false);
          setImage(response?.data?.data?.filename || '');
          setImageurl(response?.data?.data?.imageurl || '');
        } else {
          setImage('');
          setImageurl('');
          setLoader(false);
          toast.error(messages.error);
        }
      }
    } catch (error) {
      setImage('');
      setImageurl('');
      setLoader(false);
      console.error("Error occurred", error);
    }
  };

  const handleDrop = (fileData: any) => {
    handleImageUpload(fileData);
  };

  return (
   <>
    <Modal>
      <div className="w-[30rem]">
        <div className="bg-white">
          <div className="px-4 py-4 flex flex-row justify-between">
            <h2 className="font-[600] text-[18px]">{editMode? 'Edit':'Add'} Module</h2>
            <div>
              <i onClick={ onClose } className="lni lni-close cursor-pointer font-[600] hover:text-red-600"></i>{" "}
            </div>
          </div>
          <div className="px-5 py-3">
            <div className="form-control">
              <label htmlFor="user">Module Name</label>
              <input type="text" placeholder="Enter module name" id="moduleName" name="moduleName" value={moduleName} onChange={(event) => { setModuleName(event.target.value);}} />
              {errors?.moduleName && <span className="text-red-600 text-sm ms-2 font-medium">{errors.moduleName}</span>}
            </div>
            <div className="form-control">
              <label htmlFor="user">Module Description</label>
              <textarea placeholder="Enter module description" id="moduleDescription" name="moduleDescription" rows={3} value={moduleDescription} onChange={(event) => { setModuleDescription(event.target.value);}}></textarea>
              {errors?.moduleDescription && <span className="text-red-600 text-sm ms-2 font-medium">{errors.moduleDescription}</span>}
            </div>
            <div className="form-control">
              <label htmlFor="name">Upload File</label>
              <FileDragAndDrop onDrop={handleDrop} fileName={imageurl}/>
              {errors?.imageurl && <span className="text-red-600 text-sm ms-2 font-medium">{errors.imageurl}</span>}
            </div>
          </div>
        </div>
        <div className="border-t-2 border-slate-100 px-4 py-3 flex justify-end">
          <button type="button" onClick={ onClose } className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"> Cancel </button>
          { !editMode ? 
            (<button type="button" onClick={ handleSubmit } className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"> Submit </button>):
            (<button type="button" onClick={ handleUpdate } className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"> Update </button>)
          }
        </div>
      </div>
    </Modal>
    { loader && <Loader/> }
   </>
  );
};

export default AddModule;
