import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // or any other theme/style you prefer

interface EditorProps {
  initialValue: string;
  onEditorChange: (value: string) => void;
  placeholder?: string
}

const RichTextEditor: React.FC<EditorProps> = ({ initialValue, onEditorChange, placeholder }) => {
  const [editorHtml, setEditorHtml] = useState<string>(initialValue);

  useEffect(() => {
    setEditorHtml(initialValue);
  }, [initialValue]);

  const handleChange = (html: string): void => {
    setEditorHtml(html);
    onEditorChange(html);
  };

  return (
    <div className='mt-1'>
      <ReactQuill
        value={editorHtml}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default RichTextEditor;
