import http from "../configuration/axios";
import { apiRoute } from "../utils/apiroutes";

export class SubCategoryService {
    static async getSubCategoryList(pageNo?: any, pageSize?:any, search?: any, category?: any) {
        try {

            let url = `${apiRoute.subcategory.getSubCategory}`;
            if(pageNo && pageSize){
                url = url + `?pageSize=${pageSize}&pageNo=${pageNo}`;
            }

            if(search?.trim()){
                url = url + `&search=${search}`;
            }

            if(category){
                url = url + `&category=${category}`;
            }

            return (await http.get(`${url}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            }));
            
        } catch (error) {
            throw error;
        }
    }

    static async getSubCategoryListByCategoryId(id: any) {
        try {
            return (await http.get(`${apiRoute.subcategory.subcategoryByCategory}/${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async saveSubCategory(data: any) {
        try {
            return (await http.post(`${apiRoute.subcategory.createtSubCategory}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async updateSubCategory(data: any) {
        try {
            return (await http.put(`${apiRoute.subcategory.updateSubCategory}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async deleteSubCategory(id: any) {
        try {
            return (await http.delete(`${apiRoute.subcategory.deleteSubCategory}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async revertSubCategory(id: any) {
        try {
            return (await http.put(`${apiRoute.subcategory.revertSubCategory}${id}`, {}, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }
}