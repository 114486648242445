import React, { useEffect, useState } from 'react';
import { DashboardService } from '../../services/DashboardService';
import { toast } from 'react-toastify';
import { messages } from '../../utils/messages';

const Dashboard = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [dashboardCount, setDashboardCount] = useState<any>(null);

  useEffect(()=>{
    getDashboardCount();
  },[])

  const getDashboardCount = async () => {
    try {
      setLoader(true);
      const response: any = await DashboardService.getDashboardCount();
      if (response?.status === 200) {
        setDashboardCount(response.data?.data);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  return (
    <section>
      <div className="w-full flex flex-row gap-3">
        <div className="bg-white rounded-lg w-3/12 px-6 py-4">
          <div className='flex flex-row justify-between items-center'>
            <div className="font-bold text-[40px] mb-2">{dashboardCount?.productCount}</div>
            <div className='bg-orange-100 p-3 rounded-full'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 stroke-orange-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
                </svg>
            </div>
          </div>
          <p className="text-slate-500 text-base">Total Active product</p>
        </div>
        <div className="bg-white rounded-lg w-3/12 px-6 py-4">
          <div className='flex flex-row justify-between items-center'>
            <div className="font-bold text-[40px] mb-2">{dashboardCount?.userCount}</div>
            <div className='bg-blue-100 p-3 rounded-full'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 stroke-blue-500">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
              </svg>
            </div>
          </div>
          <p className="text-slate-500 text-base">Total Active User</p>
        </div>
        <div className="bg-white rounded-lg w-3/12 px-6 py-4">
          <div className='flex flex-row justify-between items-center'>
            <div className="font-bold text-[40px] mb-2">{dashboardCount?.totalPayment}<span className="text-[30px] text-secondary font-semibold"> ₹</span></div>
            <div className='bg-emerald-100 p-3 rounded-full'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 stroke-emerald-500">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
              </svg>
            </div>
          </div>
          <p className="text-slate-500 text-base">Total Payment Collection</p>
        </div>
        <div className="bg-white rounded-lg w-3/12 px-6 py-4">
          <div className='flex flex-row justify-between items-center'>
            <div className="font-bold text-[40px] mb-2">{dashboardCount?.enquiryCount}</div>
            <div className='bg-indigo-100 p-3 rounded-full'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 stroke-indigo-500">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
              </svg>
            </div>
          </div>
          <p className="text-slate-500 text-base">Total Enquires</p>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
