import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import { ModuleService } from "../../services/ModuleService";
import { toast } from "react-toastify";
import { messages } from "../../utils/messages";
import { CategoryService } from "../../services/CategoryService";
import Loader from "../ui/Loader";

const AddCategory = ({ onClose, onSubmit, editMode, categoryData  }: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [categoryName, setcategoryName] = useState(editMode? categoryData?.name : '');
  const [moduelId, setModuelId] = useState(editMode? categoryData?.module_id : '');
  const [moduleList, setModuleList] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    let errors: any = {};
    let isValid = true;

    if (!categoryName.trim()) {
      errors.categoryName = 'Category name is required.';
      isValid = false;
    }

    if (!moduelId) {
      errors.moduelId = 'Module name is required.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    getModuleList();
  }, [])

  const getModuleList = async () => {
    try {
      setLoader(true);
      const response: any = await ModuleService.getModuleList();
      if (response?.status === 200) {
        setModuleList(response?.data?.data?.content || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { name: categoryName, module_id: moduelId, status: 1 };
        const response: any = await CategoryService.saveCategory(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.category?.createdSuccess);
          onSubmit();
        } else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.category?.alreadyExist);
        } else {
          handleRequestError();
        }
      } catch (error) {
        handleRequestError();
      }
    }
  };

  const handleUpdate = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { id: categoryData?.id, module_id: moduelId, name: categoryName };
        const response: any = await CategoryService.updateCategory(data);
        if (response?.status === 200) {
          setLoader(false);
          toast.success(messages.category?.updatedSuccess);
          onSubmit();
        } else if (response?.status === 204) {
          setLoader(false);
          toast.error(messages.category?.alreadyExist);
        } else {
          handleRequestError();
        }
      } catch (error) {
        handleRequestError();
      }
    }
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  return (
    <>
      <Modal>
        <div className="w-[30rem]">
          <div className="bg-white">
            <div className="px-4 py-4 flex flex-row justify-between">
              <h2 className="font-[600] text-[18px]">{editMode? 'Edit':'Add'} Category</h2>
              <div>
                <i onClick={onClose} className="lni lni-close cursor-pointer font-[600] hover:text-red-600"></i>{" "}
              </div>
            </div>
            <div className="px-5 py-3">
              <div className="form-control">
                <label htmlFor="user">Category Name</label>
                <input type="text" placeholder="Enter category name" name="categoryName" value={categoryName} onChange={(event) => { setcategoryName(event.target.value);}}/>
                {errors?.categoryName && <span className="text-red-600 text-sm ms-2 font-medium">{errors.categoryName}</span>}
              </div>
              <div className="form-control">
                <label htmlFor="user">Module</label>
                <select name="moduelId" value={moduelId} onChange={(event) => { setModuelId(event.target.value);}}>
                  <option value="" selected hidden>Select Module</option>
                { moduleList?.map((moduel: any) => (
                  <option key={moduel?.id} value={moduel?.id}>{moduel?.name}</option>
                )) }
                </select>
                {errors?.moduelId && <span className="text-red-600 text-sm ms-2 font-medium">{errors.moduelId}</span>}
              </div>
            </div>
          </div>
          <div className="border-t-2 border-slate-100 px-4 py-3 flex justify-end">
          <button type="button" onClick={onClose} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"> Cancel </button>
            { !editMode ? 
              (<button type="button" onClick={ handleSubmit }  className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"> Submit </button>) :
              (<button type="button" onClick={ handleUpdate }  className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"> Update </button>)
            }
          </div>
        </div>
      </Modal>
      { loader && <Loader/> }
    </>
  );
};

export default AddCategory;
