const StatusBadge = ({ status }: any) => {
    return (
        <>
            { status === 1 && (
                <div className="px-3 py-[4px] bg-green-100 text-green-500 text-sm font-semibold w-fit rounded-md">ACTIVE</div>
             )}
            { (status === 2 || status === 0) && (
                <div className="px-3 py-[4px] bg-red-100 text-red-500 text-sm font-semibold w-fit rounded-md">INACTIVE</div>
            )}
            { (status === 3) && (
                <div className="px-3 py-[4px] bg-amber-100 text-amber-500 text-sm font-semibold w-fit rounded-md">IN PROGRESS</div>
            )}
            { (status === 4) && (
                <div className="px-3 py-[4px] bg-rose-100 text-rose-500 text-sm font-semibold w-fit rounded-md">REJECTED</div>
            )}
            { (status === 5) && (
                <div className="px-3 py-[4px] bg-blue-100 text-blue-500 text-sm font-semibold w-fit rounded-md">CLOSE</div>
            )}
            { (status === 6) && (
                <div className="px-3 py-[4px] bg-blue-100 text-blue-500 text-sm font-semibold w-fit rounded-md">ACCEPTED</div>
            )}
        </>
    )
}

export default StatusBadge;