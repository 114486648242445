import React, { useEffect, useRef, useState } from 'react';
import UserAccountMenu from '../ui/UserAccountMenu';
import { useUser } from '../../hooks/UseUser';

const Header = () => {
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
  const userMenuRef = useRef<any>(null);
  const userAvatarRef = useRef<any>(null);
  const { userDetails  } = useUser();

  const handleUserMenu = (event: any) => {
    setShowUserMenu(!showUserMenu);
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
        if ((userMenuRef.current && !userMenuRef.current.contains(event.target)) && (userAvatarRef.current && !userAvatarRef.current.contains(event.target)) ) {
            setShowUserMenu(false);
        }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className="bg-white border-b divide-inherit w-[calc(100%-270px)] h-[70px] max-h-[70px] fixed top-0">
          <nav className="mx-auto w-full flex items-center px-4 py-3 flex flex-row justify-end" aria-label="Global">
              <div ref={userAvatarRef}>
                <div onClick={ (event) => { handleUserMenu(event) } } className={`cursor-pointer bg-zinc-200 text-gray-500 ring-offset-2 hover:ring-2 hover:text-primary hover:bg-blue-50 ring-primary transition duration-300 uppercase w-[45px] h-[45px] rounded-full font-medium flex items-center justify-center text-[18px] ${showUserMenu? 'ring-2 text-primary bg-blue-50':''}`}>
                  {userDetails?.name?.slice(0,1)}
                </div>
              </div>
          </nav>
      </header>
      <div ref={userMenuRef}> {showUserMenu && (<UserAccountMenu/>)} </div>
    </>
  );
};

export default Header;
