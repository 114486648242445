import React, { useEffect, useState } from 'react';
import DeleteConfirmationModal from '../ui/DeleteConfirmationModal';
import { ProductService } from '../../services/ProductService';
import { messages } from '../../utils/messages';
import { toast } from 'react-toastify';
import StatusBadge from '../ui/StatusBadge';
import Loader from '../ui/Loader';
import Pagination from '../ui/Pagination';
import { useNavigate } from 'react-router-dom';
import PayStatusBadge from '../ui/PayStatusBadge';
import RevertConfirmationModal from '../ui/RevertConfirmationModal';
import { CategoryService } from '../../services/CategoryService';
import Search from '../ui/Search';
import { ModuleService } from '../../services/ModuleService';

const ProductList = () => {
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [openRevertConfirmationModal, setOpenRevertConfirmationModal] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [productList, setProductList] = useState<any[]>([]);
  const [moduleList, setModuleList] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [pageSize, setPageSize] = useState<any>(10);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [moduleId, setModuleId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState<any>(0);
  const [pageNo, setPageNo] = useState<any>(1);
  const navigate = useNavigate();
  
  const handleDeleteConfirmationModal = (flag: boolean, product?: any) => { 
    setSelectedProduct(product || null);
    setOpenDeleteConfirmationModal(flag);
  };

  const handleRevertConfirmationModal = (flag: boolean, product?:any) => { 
    setSelectedProduct(product || null);
    setOpenRevertConfirmationModal(flag) 
  };

  const navigateToAddProduct = () => {
    navigate("/app/products/add-product");
  }

  const navigateToEditProduct = (id: any) => {
    navigate("/app/products/edit-product/"+id);
  }

  useEffect(() => {
    getCategoryList();
    getModuleList();
  }, [])

  useEffect(()=>{
    getProductList();
  }, [pageNo])

  const getProductList = async () => {
    try {
      setLoader(true);
      const response: any = await ProductService.getProductList(pageNo, pageSize, searchText, moduleId, categoryId);
      if (response?.status === 200) {
        setProductList(response?.data?.data?.content || []);
        setTotalCount(response?.data?.data?.totalCount);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const getModuleList = async () => {
    try {
      setLoader(true);
      const response: any = await ModuleService.getModuleList();
      if (response?.status === 200) {
        setModuleList(response?.data?.data?.content || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const getCategoryList = async () => {
    try {
      setLoader(true);
      const response: any = await CategoryService.getCategoryList();
      if (response?.status === 200) {
        let data = response?.data?.data?.content?.filter((el: any) => el.status == 1);
        setCategoryList(data || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  const deleteProduct = async (id: any) => {
    try {
      setLoader(true);
      const response = await ProductService.deleteProduct(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.product?.deletedSuccess);
        setOpenDeleteConfirmationModal(false);
        setSelectedProduct(null);
        getProductList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const revertProduct = async (id: any) => {
    try {
      setLoader(true);
      const response = await ProductService.revertProduct(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.product?.revertedSuccess);
        setOpenRevertConfirmationModal(false);
        setSelectedProduct(null);
        getProductList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handlePageChange = (data: any) => {
    setPageNo(data);
  }

  const handleApplyFilters = () => {
    setPageNo(1);
    getProductList();
  }

  const handleResetFilter = async () => {
    setPageNo(1);
    setCategoryId('');
    setSearchText(''); 
    setModuleId('')
    setLoader(true);
      const response: any = await ProductService.getProductList(1, pageSize);
      if (response?.status === 200) {
        setProductList(response?.data?.data?.content || []);
        setTotalCount(response?.data?.data?.totalCount);
        setLoader(false);
      } else {
        handleRequestError();
      }
  };
   

  return (
    <section>
      <div className='flex flex-row justify-between'>
        <div>
          <h2 className='font-semibold'>Product</h2>
          <div className='text-gray-400 text-sm'>Product {'>'} Product list</div>
        </div>
        <div>
        <button type="button" onClick={ navigateToAddProduct } className="text-white bg-primary hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5">Add New</button>
        </div>
      </div>      
      <div className='bg-white rounded-lg p-4 mt-4'>
        <div className='w-full mb-5 flex justify-between'>
          <div className='flex'>
            <div className='w-[300px]'>
              <div className="relative w-full">
                <i className="absolute top-[15px] left-[10px] lni lni-search-alt text-gray-500"></i>
                <input className="w-full my-[3px] py-[8px] ps-[35px] pe-[16px] border border-[#dbdfe9] rounded-[8px]"
                  type="text" placeholder="Search by product name..." value={searchText} onChange={ (event) => { setSearchText(event.target.value) } }
                />
              </div>
            </div>
            <div className="form-control w-[230px] ms-4">
              <select name='categoryId' value={moduleId} onChange={(event) => { setModuleId(event.target.value) }}>
                <option value="" hidden>Select module</option>
                {moduleList?.map((module: any) => (
                  <option key={module?.id} value={module?.id}>{module?.name}</option>
                ))}
              </select>
            </div>
            <div className="form-control w-[230px] ms-4">
              <select name='categoryId' value={categoryId} onChange={(event) => { setCategoryId(event.target.value) }}>
                <option value="" hidden>Select category</option>
                {categoryList?.map((category: any) => (
                  <option key={category?.id} value={category?.id}>{category?.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div>
              <button onClick={ handleApplyFilters } disabled={!searchText && !categoryId && !moduleId} className='px-[25px] py-[10px] bg-secondary text-white text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300'>Apply</button>
              <button onClick={ handleResetFilter } disabled={!searchText && !categoryId && !moduleId} className='px-[25px] py-[10px] bg-slate-200 text-black text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300 disabled:text-white'>Reset</button>
          </div>
        </div>
        <div className="relative overflow-x-auto">
            <table className="w-full">
                <thead className="text-sm text-slate-400 uppercase border-b-2 border-dashed border-slate-200 text-left">
                    <tr>
                        <th scope="col" className="px-4 py-3 w-[60%] font-semibold">
                          Product Name
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          Sub Category
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          Category
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          Module
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          Type
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                          Status
                        </th>
                        <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { (productList?.length > 0) ? (productList?.map((product: any) => (
                      <tr key={product?.id} className="bg-white text-md border-b border-slate-200 text-slate-900">
                          <td className="px-4 py-3 whitespace-nowrap">
                              {product?.name}
                          </td>
                          <td className="px-4 py-3">
                              {product?.sub_category_name}
                          </td>
                          <td className="px-4 py-3">
                              {product?.category_name}
                          </td>
                          <td className="px-4 py-3">
                              {product?.module_name}
                          </td>
                          <td className="px-4 py-3">
                              <PayStatusBadge status={product?.pay_status}/>
                          </td>
                          <td className="px-4 py-3">
                              <StatusBadge status={product?.status}/>
                          </td>
                          <td className="px-4 py-3">
                            <div className='flex'>
                              <button onClick={ () => { navigateToEditProduct(product?.id) } } className='text-[18px] flex justify-center items-center me-4 text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-primary hover:ring-2 hover:ring-blue-200'><i className="lni lni-pencil-alt"></i></button>
                              { (product.status == 1)? 
                                (<button onClick={() => handleDeleteConfirmationModal(true, product)} className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-red-600 hover:ring-2 hover:ring-red-200'><i className="lni lni-trash-can"></i></button>) :
                                (<button onClick={ () => handleRevertConfirmationModal(true, product) }  className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-indigo-600 hover:ring-2 hover:ring-indigo-200 me-4'><i className="lni lni-reload"></i></button>)
                              }
                            </div>
                          </td>
                      </tr>
                    ))) : (
                      <tr><td colSpan={8} className='py-8 text-gray-500 text-center'>Product data not found.</td></tr>
                    )}
                </tbody>
            </table>
            <Pagination totalCount={totalCount} pageSize={pageSize} onPageChange={ (data) => { handlePageChange(data) } }/>
        </div>
      </div>
      { loader && <Loader/> }
      { openDeleteConfirmationModal && <DeleteConfirmationModal hide={ () => { handleDeleteConfirmationModal(false) }}  deleteConfirmation={(id: any) => deleteProduct(id)} id={selectedProduct?.id}/> }
      { openRevertConfirmationModal && <RevertConfirmationModal hide={ () => { handleRevertConfirmationModal(false) } } revertConfirmation={(id: any) => revertProduct(id)} id={selectedProduct?.id}/> }
    </section>
  );
};

export default ProductList;
