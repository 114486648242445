import React, { useEffect, useState } from "react";
import Loader from "../ui/Loader";
import { EnquireService } from "../../services/EnquireService";
import { toast } from "react-toastify";
import { messages } from "../../utils/messages";
import StatusBadge from "../ui/StatusBadge";
import Pagination from "../ui/Pagination";
import EnquiryDetails from "./EnquiryDetails";

const EnquireList = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [enquireList, setEnquireList] = useState<any[]>([]);
    const [pageSize, setPageSize] = useState<any>(10);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [pageNo, setPageNo] = useState<any>(1);
    const [openEnquiryDetailsModal, setOpenEnquiryDetailsModal] = useState(false);
    const [selectedEnquiry, setSelectedEnquiry] = useState<any>(null);

    const handleEnquiryDetailsModal = (flag: boolean, enquiry?: any) => {
        setOpenEnquiryDetailsModal(flag);
        setSelectedEnquiry(enquiry);
    };

    useEffect(()=>{
        getEnquiryList();
      },[pageNo])
    
      const getEnquiryList = async () => {
        try {
          setLoader(true);
          const response: any = await EnquireService.getEnquiryList(pageNo, pageSize);
          if (response?.status === 200) {
            setEnquireList(response?.data?.data?.content || []);
            setTotalCount(response?.data?.data?.totalCount);
            setLoader(false);
          } else {
            handleRequestError();
          }
        } catch (error) {
          handleRequestError();
        }
      };
    
      const handlePageChange = (data: any) => {
        setPageNo(data);
      }

      const handleRequestError = () => {
        setLoader(false);
        toast.error(messages.error);
      };

      const handleOnUpdate = () => {
        getEnquiryList();
        handleEnquiryDetailsModal(false);
      }

    return (
        <section>
            <div className='flex flex-row justify-between'>
            <div>
                <h2 className='font-semibold'>Category</h2>
                <div className='text-gray-400 text-sm'>Category {'>'} Category list</div>
            </div>
            </div>      
            <div className='bg-white rounded-lg p-4 mt-4'>
            <div className="relative overflow-x-auto">
                <table className="w-full">
                    <thead className="text-sm text-slate-400 uppercase border-b-2 border-dashed border-slate-200 text-left">
                        <tr>
                            <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                                Enquirer Name
                            </th>
                            <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                                Email
                            </th>
                            <th scope="col" className="px-4 py-3 w-[20%] font-semibold">
                                Phone No
                            </th>
                            <th scope="col" className="px-4 py-3 w-[10%] font-semibold">
                                Status
                            </th>
                            <th scope="col" className="px-4 py-3 w-[10%] font-semibold">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(enquireList?.length > 0) ? (enquireList?.map((enquire: any, index: number) => (
                        <tr key={index} className="bg-white text-md border-b border-slate-200 text-slate-900">
                            <td className="px-4 py-3 whitespace-nowrap">
                                {enquire?.name}
                            </td>
                            <td className="px-4 py-3">
                                {enquire?.email}
                            </td>
                            <td className="px-4 py-3">
                                {enquire?.phone_no}
                            </td>
                            <td className="px-4 py-3">
                                <StatusBadge status={enquire.status}/>
                            </td>
                            <td className="px-4 py-3">
                                <div className='flex'>
                                    <button onClick={ () => handleEnquiryDetailsModal(true, enquire) } className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-primary hover:ring-2 hover:ring-blue-200 me-3'><i className="lni lni-eye"></i></button>
                                </div>
                            </td>
                        </tr>
                        ))):(
                        <tr><td colSpan={5} className='py-8 text-gray-500 text-center'>Enquiry not found.</td></tr>
                        )}
                        
                    </tbody>
                </table>
                <Pagination totalCount={totalCount} pageSize={pageSize} onPageChange={ (data) => { handlePageChange(data) } }/>
            </div>
            </div>
            { loader && <Loader/> }
            { openEnquiryDetailsModal && <EnquiryDetails onClose={ () => { handleEnquiryDetailsModal(false) }}  enquireId={selectedEnquiry?.id} onUpdate={ handleOnUpdate }/> }
        </section>
    )
}

export default EnquireList;