import React, { useEffect, useState } from 'react';
import DeleteConfirmationModal from '../ui/DeleteConfirmationModal';
import { UserService } from '../../services/UserService';
import { toast } from 'react-toastify';
import { messages } from '../../utils/messages';
import Loader from '../ui/Loader';
import Modal from '../ui/Modal';

const UserProductList = ({userData, onClose}: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [productList, setProductList] = useState<any[]>([]);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [productId, setProductId] = useState<any | null>(null);

  useEffect(()=>{
    getProductList();
  },[userData]);

  const handleDeleteConfirmationModal = (flag: boolean, id?:any) => { 
    setProductId(id || null);
    setOpenDeleteConfirmationModal(flag) 
  };

  const getProductList = async () => {
    try {
      setLoader(true);
      const response: any = await UserService.getUserProductList(userData?.id);
      if (response?.status === 200) {
        setProductList(response?.data?.data || []);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const deleteUser = async (id: any) => {
    try {
      setLoader(true);
      const response = await UserService.deleteUserProduct(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.user?.productRemovedSuccess);
        setOpenDeleteConfirmationModal(false);
        getProductList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  return (
    <>
      <Modal>
          <section className="w-[60vw] min-h-[400px] px-3">   
              <div className="px-4 py-4 flex flex-row justify-between">
                  <h2 className="font-[600] text-[18px]">Product List</h2>
                  <div>
                    <i onClick={onClose} className="lni lni-close cursor-pointer font-[600] hover:text-red-600"></i>{" "}
                  </div>
              </div>
              <div className='bg-white rounded-lg p-4 pt-0'>
                  <div className="relative overflow-x-auto">
                      <table className="w-full">
                          <thead className="text-sm text-slate-400 uppercase border-b-2 border-dashed border-slate-200 text-left">
                              <tr>
                                  <th scope="col" className="px-4 py-3 font-semibold w-[90%]">
                                    Product  Name
                                  </th>
                                  <th scope="col" className="px-4 py-3 font-semibold">
                                      Action
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                              { (productList?.length > 0) ? (productList?.map((product: any) => (
                              <tr key={product?.id} className="bg-white text-md border-b border-slate-200 text-slate-900">
                                  <td className="px-4 py-3">
                                      <div className=' w-[90%]'>
                                          {product?.name}
                                      </div>
                                  </td>
                                  <td className="px-4 py-3">
                                  <div className='flex'>
                                      <button onClick={ () => handleDeleteConfirmationModal(true, product?.pum_id) } className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-red-600 hover:ring-2 hover:ring-red-200 me-4'><i className="lni lni-trash-can"></i></button>
                                  </div>
                                  </td>
                              </tr>
                              ))):
                              (
                              <tr><td colSpan={5} className='py-8 text-gray-500 text-center'>Products not found.</td></tr>
                              )}
                              
                          </tbody>
                      </table>
                  </div>
              </div>
          </section>
      </Modal>
      { loader && <Loader/> }
      { openDeleteConfirmationModal && <DeleteConfirmationModal hide={ () => { handleDeleteConfirmationModal(false) } } deleteConfirmation={(id: any) => deleteUser(id)} id={productId}/> }
    </>
  );
};

export default UserProductList;
