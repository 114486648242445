import React, { useEffect, useState } from 'react';
import AddUser from './AddUser';
import DeleteConfirmationModal from '../ui/DeleteConfirmationModal';
import StatusBadge from '../ui/StatusBadge';
import { UserService } from '../../services/UserService';
import { toast } from 'react-toastify';
import { messages } from '../../utils/messages';
import Pagination from '../ui/Pagination';
import Loader from '../ui/Loader';
import AddPayment from '../payment/AddPayment';
import UserProductList from './UserProductList';
import moment from 'moment';
import RevertConfirmationModal from '../ui/RevertConfirmationModal';

const UserList = () => {
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [openRevertConfirmationModal, setOpenRevertConfirmationModal] = useState(false);
  const [showProductListModal, setShowProductListModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [pageNo, setPageNo] = useState<any>(1);
  const [searchText, setSearchText] = useState('');

  const handleUserModal = (flag: boolean, userdata?:any) => { 
    setIsEditMode(!!userdata);
    setSelectedUser(userdata || null);
    setShowUserModal(flag)
  };

  const handlePaymentModal = (flag: boolean, userdata?:any) => { 
    setSelectedUser(userdata || null);
    setShowPaymentModal(flag)
  };

  const handleProductListModal = (flag: boolean, userdata?:any) => { 
    setSelectedUser(userdata || null);
    setShowProductListModal(flag);
    getUserList();
  };

  const handleUserSubmit = () => { 
    setShowUserModal(false);
    setSelectedUser(null);
    getUserList();
  };

  const handlePaymentSubmit = () => { 
    setShowPaymentModal(false);
    setSelectedUser(null);
    getUserList();
  };

  const handleDeleteConfirmationModal = (flag: boolean, userdata?:any) => { 
    setSelectedUser(userdata || null);
    setOpenDeleteConfirmationModal(flag) 
  };

  const handleRevertConfirmationModal = (flag: boolean, userdata?:any) => { 
    setSelectedUser(userdata || null);
    setOpenRevertConfirmationModal(flag) 
  };

  useEffect(()=>{
    getUserList();
  },[pageNo])

  const getUserList = async () => {
    try {
      setLoader(true);
      const response: any = await UserService.getUserList(pageNo, pageSize, searchText);
      if (response?.status === 200) {
        setUserList(response?.data?.data?.content || []);
        setTotalCount(response?.data?.data?.totalCount);
        setLoader(false);
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const deleteUser = async (id: any) => {
    try {
      setLoader(true);
      const response = await UserService.deleteUser(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.user?.deletedSuccess);
        setOpenDeleteConfirmationModal(false);
        setSelectedUser(null);
        getUserList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const revertUser = async (id: any) => {
    try {
      setLoader(true);
      const response = await UserService.revertUser(id);
      if (response?.status === 200) {
        setLoader(false);
        toast.success(messages.user?.revertedSuccess);
        setOpenRevertConfirmationModal(false);
        setSelectedUser(null);
        getUserList();
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  const handleRequestError = () => {
    setLoader(false);
    toast.error(messages.error);
  };

  const handlePageChange = (data: any) => {
    setPageNo(data);
  }

  const handleApplyFilters = () => {
    setPageNo(1);
    getUserList();
  }

  const handleResetFilter = async () => {
    setPageNo(1);
    setSearchText((prevValue: any) => ''); 
    setLoader(true);
    const response: any = await UserService.getUserList(1, pageSize);
    if (response?.status === 200) {
      setUserList(response?.data?.data?.content || []);
      setTotalCount(response?.data?.data?.totalCount);
      setLoader(false);
    } else {
      handleRequestError();
    }
  };

  return (
    <section>
      <div className='flex flex-row justify-between'>
        <div>
          <h2 className='font-semibold'>Users</h2>
          <div className='text-gray-400 text-sm'>Users {'>'} User list</div>
        </div>
        <div>
        <button type="button" onClick={() => handleUserModal(true)} className="text-white bg-primary hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5">Add New</button>
        </div>
      </div>      
      <div className='bg-white rounded-lg p-4 mt-4'>
      <div className='w-full mb-5 flex justify-between'>
          <div className='flex'>
            <div className='w-[380px]'>
              <div className="relative w-full">
                <i className="absolute top-[15px] left-[10px] lni lni-search-alt text-gray-500"></i>
                <input className="w-full my-[3px] py-[8px] ps-[35px] pe-[16px] border border-[#dbdfe9] rounded-[8px]"
                  type="text" placeholder="Search by name, email and phone no..." value={searchText} onChange={ (event) => { setSearchText(event.target.value) } }
                />
              </div>
            </div>
          </div>
          <div>
              <button onClick={ handleApplyFilters } disabled={!searchText} className='px-[25px] py-[10px] bg-secondary text-white text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300'>Apply</button>
              <button onClick={ handleResetFilter } disabled={!searchText} className='px-[25px] py-[10px] bg-slate-200 text-black text-sm text-medium rounded-md my-[3px] ms-2 disabled:bg-gray-300 disabled:text-white'>Reset</button>
          </div>
        </div>
        <div className="relative overflow-x-auto">
            <table className="w-full">
                <thead className="text-sm text-slate-400 uppercase border-b-2 border-dashed border-slate-200 text-left">
                    <tr>
                        <th scope="col" className="px-4 py-3 font-semibold">
                            Name
                        </th>
                        <th scope="col" className="px-4 py-3 font-semibold">
                            Email
                        </th>
                        <th scope="col" className="px-4 py-3 font-semibold">
                            Phone No
                        </th>
                        <th scope="col" className="px-4 py-3 w-fit font-semibold">
                            Product
                        </th>
                        <th scope="col" className="px-4 py-3 font-semibold">
                            Status
                        </th>
                        <th scope="col" className="px-4 py-3 font-semibold">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { (userList?.length > 0) ? (userList?.map((user: any) => (
                      <tr key={user?.id} className="bg-white text-md border-b border-slate-200 text-slate-900">
                        <td className="px-4 py-3 whitespace-nowrap w-[30%]">
                            {user?.name}
                        </td>
                        <td className="px-4 py-3">
                          {user?.email}
                        </td>
                        <td className="px-4 py-3">
                          {user?.phone_no}
                        </td>
                        {/* <td className="px-4 py-3">
                          {moment(user?.last_login).format( 'DD MMM YYYY HH:mm')}
                        </td> */}
                        <td className="px-4 py-3 text-center">
                          <div className='flex justify-center'>
                            { user?.product_count > 0 ? 
                              (<div onClick={() => handleProductListModal(true, user)} className='px-3 py-1 font-semibold rounded-md bg-gray-100 cursor-pointer w-fit hover:bg-blue-100 hover:text-primary'>{user?.product_count}</div>):
                              (<div className='px-3 py-1 font-semibold rounded-md bg-gray-100 w-fit'>{user?.product_count}</div>)
                            }
                          </div>
                        </td>
                        <td className="px-4 py-3">
                            <StatusBadge status={user?.status}/>
                        </td>
                        <td className="px-4 py-3">
                          <div className='flex'>
                            <button onClick={ () => handleUserModal(true, user) } className='text-[18px] flex justify-center items-center me-4 text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-primary hover:ring-2 hover:ring-blue-200'><i className="lni lni-pencil-alt"></i></button>
                            { (user?.status === 1)? 
                              (<button onClick={ () => handleDeleteConfirmationModal(true, user) }  className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-red-600 hover:ring-2 hover:ring-red-200 me-4'><i className="lni lni-trash-can"></i></button>) :
                              (<button onClick={ () => handleRevertConfirmationModal(true, user) }  className='text-[18px] flex justify-center items-center text-zinc-600 w-[35px] h-[35px] rounded bg-slate-100 transition duration-300 hover:text-indigo-600 hover:ring-2 hover:ring-indigo-200 me-4'><i className="lni lni-reload"></i></button>)
                            }
                            { (user?.status === 1) && <button onClick={ () => handlePaymentModal(true, user) }  className='text-[14px] px-3 flex justify-center items-center text-white  rounded bg-secondary transition duration-300 hover:ring-2 hover:ring-emerald-500'>+ Payment</button>}
                          </div>
                        </td>
                    </tr>
                    ))):
                    (
                      <tr><td colSpan={5} className='py-8 text-gray-500 text-center'>User records not found.</td></tr>
                    )}
                    
                </tbody>
            </table>
            <Pagination totalCount={totalCount} pageSize={pageSize} onPageChange={ (data) => { handlePageChange(data) } }/>
        </div>
      </div>
      { loader && <Loader/> }
      { showUserModal && <AddUser editMode={isEditMode} userData={selectedUser} onSubmit={ handleUserSubmit } onClose={() => handleUserModal(false)}/> }
      { showPaymentModal && <AddPayment userData={selectedUser} onSubmit={ handlePaymentSubmit } onClose={() => handlePaymentModal(false)}/> }
      { openDeleteConfirmationModal && <DeleteConfirmationModal hide={ () => { handleDeleteConfirmationModal(false) } } deleteConfirmation={(id: any) => deleteUser(id)} id={selectedUser?.id}/> }
      { openRevertConfirmationModal && <RevertConfirmationModal hide={ () => { handleRevertConfirmationModal(false) } } revertConfirmation={(id: any) => revertUser(id)} id={selectedUser?.id}/> }
      { showProductListModal && <UserProductList onClose={ () => { handleProductListModal(false) } } userData={selectedUser} /> }
    </section>
  );
};

export default UserList;
