import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../services/AuthService';
import { toast } from 'react-toastify';
import { messages } from '../../utils/messages';
import PasswordVisibility from '../ui/PasswordVisibility';
import Loader from '../ui/Loader';

const Login = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [email, setEmail] = useState<any>('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<any>({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let errors: any = {};
    let isValid = true;

    if (!email.trim()) {
      errors.email = 'Email is required.';
      isValid = false;
    }else if (!emailRegex.test(email)) {
      errors.email = 'Invalid email format.';
      isValid = false;
    } 

    if (!password.trim()) {
      errors.password = 'Password is required.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleLogin = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { 
          username : email, 
          password : password
        };
        const response: any = await AuthService.login(data);
        if (response?.status === 200) {
          localStorage.setItem('access_token', response?.data?.data?.access_token);
          getUserDetails();
          setLoader(false);
        } else {
          setLoader(false);
          toast.error(messages?.login?.invalidCreds);
        }
      } catch (error) {
        setLoader(false);
        toast.error(messages?.login?.invalidCreds);
      }
    }
  };

  const getUserDetails = async () => {
    try { 
      setLoader(true);
      const response: any = await AuthService.getUserDetails();
      if (response?.status === 200) {
        localStorage.setItem('userdetails', JSON.stringify(response?.data?.data));
        setTimeout(() => {
          navigate('/app/dashboard');
          setLoader(false);
        }, 300);        
      } else {
        setLoader(false);
        localStorage.clear();
        toast.error(messages?.login?.userNotFound);
      }
    } catch (error) {
      setLoader(false);
      localStorage.clear();
      toast.error(messages?.login?.userNotFound);
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section className='flex flex-row'>
      <div className='w-7/12 h-[100vh] bg-primary login-bg'></div>
      <div className='w-5/12 h-[100vh] flex flex-col justify-center items-center'>
        <div className='p-7 w-[80%]'>
          <div className='mb-3'>
            <div><span className='text-[26px] font-[700] text-primary'>Meridian</span><span className='text-[26px] text-secondary'>guru</span></div>
          </div>
          <div>
            <h2 className='font-semibold text-[26px]'>Login</h2>
            <p className='text-sm text-gray-500'>Enter your email and password to access your account</p>
          </div>
          <div className='mt-5'>
            <div className="form-control">
                <label htmlFor="user">Email</label>
                <input type="text" placeholder="Enter your email" name='email' value={email} onChange={(event) => { setEmail(event.target.value);}} />
                  {errors?.email && <span className="text-red-600 text-sm ms-2 font-medium">{errors.email}</span>}
            </div>
            <div className="form-control">
                <label htmlFor="user">Password</label>
                <div className="input-group">
                    <input type={showPassword? 'text':'password'} placeholder="Enter password" name="password" value={password} onChange={(event) => { setPassword(event.target.value);}}/>
                    <PasswordVisibility visibility={showPassword} onClick={togglePasswordVisibility}/>
                </div>
                {errors?.password && <span className="text-red-600 text-sm ms-2 font-medium">{errors.password}</span>}
            </div>
            <div className='mt-4'>
              <button type="button" onClick={ handleLogin } className="text-white bg-primary hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5">Login</button>
            </div>
          </div>
        </div>
      </div>
      { loader && <Loader/> }
    </section>
  );
};

export default Login;
