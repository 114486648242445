import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthGuard({ children }: any) {
  
  function isAuthenticated() {
    const accessToken = localStorage.getItem('access_token');
    const userdetails: any = JSON.parse(localStorage.getItem('userdetails') || '');
    if (accessToken && accessToken !== '') {
      if(userdetails?.role === 'ADMIN'){
        return true;
      }
    }
    return false;
  };

  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  return children;
}
 
AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
 
export default AuthGuard;