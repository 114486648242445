import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();

    function handelNavigation(){
        const access_token = localStorage.getItem('access_token');
        if(access_token){
            navigate('/app/dashboard');
        }else{
            navigate('/login');
        }
    }

    return (
        <section className="flex flex-col items-center justify-center bg-gray-100 w-[100vw] h-[100vh]">
            <h1 className="text-[100px] font-bold">404</h1>
            <p className="text-[22px] mt-[-26px] mb-3">Page Not Found</p>
            <button onClick={handelNavigation} className="bg-secondary text-white mt-2 px-6 py-2 rounded-md hover:ring-4 ring-green-200">Home</button>
        </section>
    )
}

export default PageNotFound;