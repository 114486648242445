import http from "../configuration/axios";
import { apiRoute } from "../utils/apiroutes";

export class CategoryService {
    static async getCategoryList(pageNo?: any, pageSize?:any, search?:any) {
        try {

            let url = `${apiRoute.category.getCategory}`;
            if(pageNo && pageSize){
                url = url + `?pageSize=${pageSize}&pageNo=${pageNo}`;
            }

            if(search?.trim()){
                url = url + `&search=${search}`;
            }

            return (await http.get(`${url}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async getCategoryListByModuleId(id: any) {
        try {
            return (await http.get(`${apiRoute.category.categoryByModule}/${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async saveCategory(data: any) {
        try {
            return (await http.post(`${apiRoute.category.createCategory}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async updateCategory(data: any) {
        try {
            return (await http.put(`${apiRoute.category.updateCategory}`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async deleteCategory(id: any) {
        try {
            return (await http.delete(`${apiRoute.category.deleteCategory}${id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async revertCategory(id: any) {
        try {
            return (await http.put(`${apiRoute.category.revertCategory}${id}`, {}, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }
}